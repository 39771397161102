import React from "react";

const SendXs: React.FunctionComponent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="198"
    height="43"
    viewBox="0 0 198 43"
    fill="none"
  >
    <path d="M0 0H148.847L182 43H0V0Z" />
    <path d="M156 0H164.555L198 43H189.445L156 0Z" />
  </svg>
);

export default SendXs;
