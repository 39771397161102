import styled from 'styled-components'

const theme = {
  adaptive: {
    main: {
      xl: '@media screen and (max-width: 1870px)',
      lg: '@media screen and (max-width: 1630px)',
      slg: '@media screen and (max-width: 1395px)',
      md: '@media screen and (max-height: 640px)',
      smd: '@media screen and (max-width: 1155px)',
      sm: '@media screen and (max-width: 800px)',
      smm: '@media screen and (max-width: 410px)',
      xs: '@media screen and (max-width: 360px)',
    },
    modal: {
      md: '@media screen and (max-height: 740px)',
    },
    newDesign: {
      xl: '@media screen and (max-width: 1920px)',
      lg: '@media screen and (max-width: 1680px)',
      slg: '@media screen and (max-width: 1440px)',
      xmd: '@media screen and (max-width: 1100px)',
      md: '@media screen and (max-width: 815px)',
      sm: '@media screen and (max-width: 580px)',
      xsm: '@media screen and (max-width: 370px)',
    },
  },
}

export const Container = styled.div`
  width: 1660px;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 1454px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 1240px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 1050px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    width: 766px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 500px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 277px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    width: 250px;
  }
`

export default theme
