import eva00Img from 'img/slider/eva-00.png'
import eva01Img from 'img/slider/eva-01.png'
import eva8aImg from 'img/slider/eva-08-a.png'
import eva8bImg from 'img/slider/eva-08-b.png'
import eva13Img from 'img/slider/eva-13.png'

export interface SlideProps {
  name: string
  img: string
}

const SlideList: SlideProps[] = [
  {
    name: 'Eva - 13',
    img: eva13Img,
  },
  {
    name: 'Eva - 08 BETA',
    img: eva8bImg,
  },
  {
    name: 'Eva - 08 ALPHA',
    img: eva8aImg,
  },
  {
    name: 'Eva - 01',
    img: eva01Img,
  },
  {
    name: 'Eva - 00',
    img: eva00Img,
  },
]

export default SlideList
