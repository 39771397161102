export const API_URL = process.env.REACT_APP_API_URL
export const API_BASIC_AUTH_LOGIN = process.env.REACT_APP_AUTH_LOGIN
export const API_BASIC_AUTH_PASS = process.env.REACT_APP_AUTH_PASS
export const DECK = 'https://docsend.com/view/3wkjx8sfv9w37bp9'
export const YOUTUBE_LINK = ''
export const TWITTER_LINK = 'https://twitter.com/solana_prime'
export const TELEGRAM_LINK = 'https://t.me/SolanaPrimeOfficial'
export const DISCORD_LINK = 'https://discord.gg/pAMHcwh6MX'
export const MEDIUM_LINK = 'https://medium.com/solanaprime'
export const APPLY_LINK =
  'https://docs.google.com/forms/d/e/1FAIpQLSfKh6MDR15K9AB570RNJVPnUjlhBUcV6XdBkGRjKXeM9rK3Ew/viewform'
export const FAQ_LINK = 'https://solanaprime.gitbook.io/solanaprime-faq/'
export const APP_LINK = 'https://app.solanaprime.com/'
export const BUY_TOKEN_LINK =
  'https://jup.ag/swap/PRIME-USDC'
