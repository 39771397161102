import React, { useState } from 'react'
import styled from 'styled-components'
import Ticker from 'react-ticker'
import PartnersItem from './PartnersItem'
import partnersList from '../../../store/partners'
import partnersBg from 'img/new/partners/bg.svg'
import { Container } from 'theme/theme'
import PageVisibility from 'react-page-visibility'

const Partners: React.FunctionComponent = () => {
  const [isPlay, setIsPlay] = useState(true)
  const [pageIsVisible, setPageIsVisible] = useState(true)

  const handleVisibilityChange = (
    isVisible: boolean | ((prevState: boolean) => boolean)
  ) => {
    setPageIsVisible(isVisible)
  }

  const onEnter = () => {
    setIsPlay(false)
  }

  const onLeave = () => {
    setIsPlay(true)
  }

  return (
    <Root>
      <Container>
        <TitleWrap>
          <SubTitle>ぜひご参加ください</SubTitle>
          <Title>Partners</Title>
        </TitleWrap>
      </Container>
      <PageVisibility onChange={handleVisibilityChange}>
        {pageIsVisible && (
          <Ticker move={isPlay}>
            {({ index }) => (
              <SliderContainer
                count={partnersList.length}
                onMouseEnter={onEnter}
                onMouseLeave={onLeave}
              >
                {partnersList.map((partner, idx) => {
                  return (
                    <PartnersItem
                      key={idx}
                      link={partner.link}
                      imageSrc={partner.logo}
                    />
                  )
                })}
              </SliderContainer>
            )}
          </Ticker>
        )}
      </PageVisibility>
    </Root>
  )
}

export default Partners

const Root = styled.div`
  position: relative;
  padding-bottom: 229px;
  background: url('${partnersBg}') center no-repeat;
  background-size: cover;
  margin-top: -127px;
  padding-top: 137px;
  padding-bottom: 290px;
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    padding-top: 107px;
    padding-bottom: 250px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    padding-bottom: 220px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    padding-top: 90px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    padding-top: 130px;
    padding-bottom: 159px;
  }
`

const TitleWrap = styled.div`
  margin-top: 15px;
`

const SubTitle = styled.div`
  font-family: 'ABCDiatype';
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #ed1651;
  margin-bottom: 8px;
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 14px;
    line-height: 18px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 11px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    font-size: 10px;
    line-height: 13px;
    margin-bottom: 13px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 8px;
    line-height: 10px;
    margin-bottom: 7px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    margin-bottom: 6px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    font-size: 10px;
    line-height: 13px;
    margin-bottom: 5px;
  }
`

const Title = styled.div`
  font-family: 'PressStart2P';
  font-weight: 400;
  font-size: 53px;
  line-height: 120%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #8cc8bc;
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 47px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 41px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    font-size: 34px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 19px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    font-size: 20px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    font-size: 23px;
  }
`

interface tickerProps {
  count: number
}

const SliderContainer = styled.div<tickerProps>`
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 185px;
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    margin-top: 105px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    margin-top: 77px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    margin-top: 65px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: ${({ count }) =>
      count ? `${(count / 2) * 280}px` : '100%'} !important;
    margin-top: 27px;
    flex-wrap: wrap;
  }
`
