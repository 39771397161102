import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import Header from './header'
import Main from './main'
import About from './about'
import robotImg from 'img/new/main/robot.png'
import robotSmImg from 'img/new/main/robot-sm.png'
import robotAnimImg from 'img/new/main/robot-anim.png'
import Tiers from './tiers'
import RoadMap from './roadmap'
import Partners from './partners'
import Footer from './footer'
import { FadeIn, FadeOut } from './animation'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import useWindowSize from 'helpers/utils/useWindowSize'
import ContactForm from '../modals/ContactForm'

const Index: React.FunctionComponent = () => {
  const robotRef = useRef(null)
  const robotAnimRef = useRef(null)
  const { width } = useWindowSize()
  gsap.registerPlugin(ScrollTrigger)
  const [formModalOpen, setFormModalOpen] = useState(false)

  const openModal = (): void => {
    setFormModalOpen(true)
  }

  useEffect(() => {
    if (width > 580) {
      if (robotRef.current) {
        FadeOut(robotRef.current)
      }
      if (robotAnimRef.current) {
        FadeIn(robotAnimRef.current)
      }
    }
  }, [])

  return (
    <Root>
      <ContactForm
        active={formModalOpen}
        onClose={() => setFormModalOpen(false)}
      />
      <Header openModal={openModal} />
      <Main />
      <About />
      <Tiers />
      <RoadMap />
      <Partners />
      <Footer openModal={openModal} />
      <Robot ref={robotRef} />
      <RobotAnim ref={robotAnimRef} />
    </Root>
  )
}

export default Index

const Root = styled.div`
  font-family: 'ABCDiatypeMono';
  position: relative;
  background: #181717;
  width: 100%;
  overflow: hidden;
`

const RobotCss = css`
  position: absolute;
  top: 0px;
  left: -270px;
  width: 1219px;
  height: 1274px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    left: -240px;
    width: 1065px;
    height: 1114px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    left: -224px;
    width: 933px;
    height: 976px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    left: -167px;
    width: 721px;
    height: 754px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    left: -87px;
    width: 590px;
    height: 616px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    left: -63px;
    width: 319px;
    height: 333px;
  }
`

const Robot = styled.div`
  background: url('${robotImg}') center no-repeat;
  background-size: cover;
  ${RobotCss};
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    background: url('${robotSmImg}') center no-repeat;
    background-size: cover;
  }
`

const RobotAnim = styled.div`
  background: url('${robotAnimImg}') center no-repeat;
  background-size: cover;
  opacity: 0;
  ${RobotCss};
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    display: none;
  }
`
