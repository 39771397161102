import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import SlideList from '../../../../store/slides'
import Slide from './Slide'
import { ReactComponent as NextBtnImg } from 'img/new/tiers/slider/next.svg'
import { ReactComponent as PrevBtnImg } from 'img/new/tiers/slider/prev.svg'
import btnBg from 'img/new/tiers/slider/btn-bg.svg'
import { SwipeEventData, useSwipeable } from 'react-swipeable'

interface sliderProps {
  isShowAll: boolean
  isTurnOverAll: number
}

const Slider: React.FunctionComponent<sliderProps> = (props) => {
  const [activeSlide, setActiveSlide] = useState(6)
  const [isShowAll, setIsShowAll] = useState(false)
  const [goToSlide, setGoToSlide] = useState(0)
  const [isAnim, setIsAnim] = useState(false)

  const toNextSlide = () => {
    if (!isAnim && !isShowAll) {
      setIsAnim(true)
      setGoToSlide(activeSlide + 1)
    }
  }

  const toPrevSlide = () => {
    if (!isAnim && !isShowAll) {
      setIsAnim(true)
      setGoToSlide(activeSlide - 1)
    }
  }

  const onSlideClick = (id: number) => {
    if (!isAnim) {
      setIsAnim(true)
      setGoToSlide(id)
    }
  }

  const onTransformEnd = (newSlide: number) => {
    setActiveSlide(newSlide)
    setIsAnim(false)
  }

  const swipeHandlers = useSwipeable({
    onSwipedLeft: (eventData) => toNextSlide(),
    onSwipedRight: (eventData) => toPrevSlide(),
    // preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    trackTouch: true,
    delta: 30,
  })

  useEffect(() => {
    if (!isAnim) {
      setIsAnim(true)
      setIsShowAll(props.isShowAll)
    }
  }, [props.isShowAll])

  return (
    <Root {...swipeHandlers}>
      <ControlsWrap>
        <PrevBtn hide={isShowAll} onClick={toPrevSlide}>
          <PrevBtnImg />
          <BtnBg />
        </PrevBtn>
        <NextBtn hide={isShowAll} onClick={toNextSlide}>
          <NextBtnImg />
          <BtnBg />
        </NextBtn>
      </ControlsWrap>

      {SlideList.map((item, idx) => {
        return (
          <Slide
            activeSlide={activeSlide}
            key={idx + 1}
            image={item.img}
            id={idx + 1}
            realId={idx + 1}
            slideVisible={SlideList.length}
            goToSlide={goToSlide}
            onTransformEnd={onTransformEnd}
            onSlideClick={onSlideClick}
            isShowAll={isShowAll}
            turnOver={props.isTurnOverAll}
          />
        )
      })}
      {SlideList.map((item, idx) => {
        return (
          <Slide
            activeSlide={activeSlide}
            key={idx + 6}
            image={item.img}
            id={idx + 6}
            realId={idx + 1}
            slideVisible={SlideList.length}
            goToSlide={goToSlide}
            onTransformEnd={onTransformEnd}
            onSlideClick={onSlideClick}
            isShowAll={isShowAll}
            turnOver={props.isTurnOverAll}
          />
        )
      })}
      {SlideList.map((item, idx) => {
        return (
          <Slide
            activeSlide={activeSlide}
            key={idx + 11}
            image={item.img}
            id={idx + 11}
            realId={idx + 1}
            slideVisible={SlideList.length}
            goToSlide={goToSlide}
            onTransformEnd={onTransformEnd}
            onSlideClick={onSlideClick}
            isShowAll={isShowAll}
            turnOver={props.isTurnOverAll}
          />
        )
      })}
    </Root>
  )
}

export default Slider

const Root = styled.div`
  position: relative;
  width: 100%;
  user-select: none;
  overflow: visible;
`

interface ControlsProps {
  hide: boolean
}

const ArrowCss = css<ControlsProps>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 54px;
  height: 54px;
  z-index: 5;
  transition: 0.3s;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  pointer-events: ${({ hide }) => (hide ? 'none' : 'auto')};
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 43px;
    height: 43px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 42px;
    height: 42px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    width: 33px;
    height: 33px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 22px;
    height: 22px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    width: 21px;
    height: 21px;
  }
  svg {
    transition: 0.4s;
    width: 100%;
    height: 100%;
    fill: #ed1651;
  }
  &:hover {
    svg {
      fill: #ff3d72;
    }
  }
`

const PrevBtn = styled.button<ControlsProps>`
  ${ArrowCss};
  left: 0;
`

const NextBtn = styled.button<ControlsProps>`
  ${ArrowCss};
  right: 0;
`

const ControlsWrap = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 1074px;
  z-index: 5;
  pointer-events: none;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 1053px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 897px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 728px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    width: 691px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 435px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 291px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    width: 100px;
    top: 217px;
  }
`

const BtnBg = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 105px;
  height: 93px;
  background: url('${btnBg}') center no-repeat;
  background-size: cover;
  pointer-events: none;
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 97px;
    height: 86px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 94px;
    height: 85px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    width: 74px;
    height: 65px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 49px;
    height: 43px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    width: 48px;
    height: 42px;
  }
`
