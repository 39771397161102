import React, { useState } from 'react'
import styled from 'styled-components'
// @ts-ignore
import VisibilitySensor from 'react-visibility-sensor-v2'

interface partnerProps {
  link: string
  imageSrc: string
}

const PartnersItem: React.FunctionComponent<partnerProps> = (props) => {
  const [isFade, setIsFade] = useState(false)

  function onChange(isVisible: any) {
    if (isVisible) {
      setIsFade(false)
    } else {
      setIsFade(true)
    }
  }

  return (
    <Item target={'_blank'} href={props.link} fade={isFade}>
      <VisibilitySensor onChange={onChange}>
        <ItemImg src={props.imageSrc} />
      </VisibilitySensor>
    </Item>
  )
}

export default PartnersItem

interface fadeProps {
  fade: boolean
}

const Item = styled.a<fadeProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 40px;
  width: 245px;
  height: 130px;
  outline: none;
  text-decoration: none;
  z-index: 10;
  opacity: ${({ fade }) => (fade ? '0.5' : '1')};
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 215px;
    height: 115px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 200px;
    height: 108px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    width: 190px;
    height: 100px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 150px;
    height: 75px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 140px;
    height: 70px;
    margin-top: 20px;
  }
`

const ItemImg = styled.img`
  display: block;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
`
