import React from 'react'
import styled from 'styled-components'
import arrowImg from 'img/new/about/arrow.svg'
import rectangleImg from 'img/new/about/rect.svg'
import attentionImg from 'img/new/about/attention.svg'
import headImg from 'img/new/about/robot-head.svg'
import bigRectImg from 'img/new/about/rect-big.svg'
import yapanRect from 'img/new/about/yapan-rect.svg'
import lightImg from 'img/new/about/light.svg'
import AboutSlider from './slider'
import { Container } from 'theme/theme'

const About: React.FunctionComponent = () => {
  return (
    <Root>
      <Container>
        {' '}
        <LeftSide>
          <TitleWrap>
            <SubTitle>
              Join the community-led launchpad, accelerator and VC DAO to{' '}
            </SubTitle>
            <Title>supercharge Solana</Title>
          </TitleWrap>
          <Images>
            <RobotHead />
            <YapanBlock>
              <YapanRect />
              <YapanText>
                ソラナプライムはよりスマートな
                ランチパッドとIDOデザインを導入しています
              </YapanText>
            </YapanBlock>
            <BigRect />
            <AttentionWrap>
              <AttentionImg />
              <AttentionText>
                SolanaPrime is introducing smarter Launchpad and IDO design
              </AttentionText>
            </AttentionWrap>
            <Arrow />
            <Rectangle />
          </Images>
        </LeftSide>
        <SliderWrap>
          <AboutSlider />
        </SliderWrap>
      </Container>
    </Root>
  )
}

export default About

const Root = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 134px 0 0 0;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    padding: 154px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    padding: 113px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    padding: 92px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    padding: 51px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    padding: 45px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    padding: 23px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    padding: 18px 0 0 0;
  }
  &:before {
    content: '';
    position: absolute;
    top: -631px;
    left: -580px;
    width: 3308px;
    height: 3339px;
    background: url('${lightImg}') center no-repeat;
    background-size: cover;
  }
  ${Container} {
    position: relative;
    display: flex;
  }
`

const LeftSide = styled.div``

const TitleWrap = styled.div`
  position: relative;
  font-family: 'PressStart2P';
  width: 795px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 795px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 523px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 490px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 352px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 231px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    width: 204px;
  }
`

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 21px;
  line-height: 140%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fff0cf;
  margin-bottom: 15px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 18px;
    margin-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 14px;
    margin-bottom: 18px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 13px;
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 10px;
    margin-bottom: 10px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    font-size: 6px;
    margin-bottom: 8px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    font-size: 5px;
  }
`

const Title = styled.div`
  font-weight: 400;
  font-size: 56px;
  line-height: 110%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ed1651;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 53px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 45px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 40px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 30px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    font-size: 20px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    font-size: 18px;
  }
`

const Images = styled.div`
  position: relative;
  display: flex;
  align-items: end;
  margin-top: 432px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    margin-top: 432px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    margin-top: 330px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    margin-top: 465px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    display: block;
    margin-top: 450px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    margin-top: 317px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 100%;
    margin-top: 393px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    margin-top: 390px;
  }
`

const Arrow = styled.div`
  position: absolute;
  top: -234px;
  left: 0;
  width: 141px;
  height: 155px;
  background: url('${arrowImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    top: -364px;
    width: 141px;
    height: 155px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    top: -244px;
    width: 107px;
    height: 117px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    top: -350px;
    width: 141px;
    height: 155px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    left: 629px;
    top: -596px;
    width: 115px;
    height: 127px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    left: 415px;
    top: -420px;
    width: 65px;
    height: 72px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    left: unset;
    right: -50px;
    top: -458px;
    width: 37px;
    height: 41px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    right: -45px;
    top: -445px;
    width: 34px;
    height: 37px;
  }
`

const Rectangle = styled.div`
  position: absolute;
  left: 444px;
  top: -192px;
  width: 62px;
  height: 55px;
  background: url('${rectangleImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    left: 172px;
    top: -192px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    left: 150px;
    top: -336px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    left: 167px;
    top: -396px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    display: none;
  }
`

const AttentionWrap = styled.div`
  position: absolute;
  width: 311px;
  left: 538px;
  bottom: 377px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 256px;
    left: 315px;
    bottom: calc(100% + 82px);
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 223px;
    left: 268px;
    bottom: calc(100% + 67px);
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 233px;
    left: 237px;
    bottom: calc(100% + 173px);
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    width: 186px;
    left: 103px;
    bottom: calc(100% + 108px);
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 129px;
    left: 14px;
    bottom: calc(100% + 134px);
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 100%;
    display: flex;
    align-items: end;
    left: 0;
    bottom: calc(100% + 326px);
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    bottom: calc(100% + 316px);
  }
`

const AttentionImg = styled.div`
  width: 298px;
  height: 257px;
  background: url('${attentionImg}') center no-repeat;
  background-size: cover;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 256px;
    height: 221px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 223px;
    height: 193px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 209px;
    height: 181px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    width: 169px;
    height: 146px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 117px;
    height: 101px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 54px;
    height: 46px;
  }
`

const AttentionText = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 118%;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: capitalize;
  color: #fff0cf;
  margin-top: 24px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 18px;
    margin-top: 19px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 18px;
    margin-top: 15px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 12px;
    margin-top: 25px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    margin-top: 19px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 8px;
    margin-top: 13px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 140px;
    margin-top: 0;
    margin-left: 10px;
    text-align: left;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    margin-left: 13px;
    width: 122px;
    font-size: 9px;
  }
`

const RobotHead = styled.div`
  position: relative;
  width: 451px;
  height: 473px;
  background: #8cc8bc;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 318px;
    height: 333px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 330px;
    height: 347px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 250px;
    height: 262px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    width: 205px;
    height: 215px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 139px;
    height: 146px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    display: none;
  }
  &:before {
    content: '';
    position: absolute;
    top: -133px;
    left: 23px;
    width: 406px;
    height: 593px;
    background: url('${headImg}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.newDesign.xl} {
      top: -94px;
      left: 13px;
      width: 289px;
      height: 416px;
    }
    ${({ theme }) => theme.adaptive.newDesign.lg} {
      top: -98px;
      left: 14px;
      width: 301px;
      height: 433px;
    }
    ${({ theme }) => theme.adaptive.newDesign.slg} {
      top: -78px;
      left: 8px;
      width: 228px;
      height: 321px;
    }
    ${({ theme }) => theme.adaptive.newDesign.xmd} {
      top: -63px;
      left: 6px;
      width: 187px;
      height: 263px;
    }
    ${({ theme }) => theme.adaptive.newDesign.md} {
      top: -43px;
      left: 4px;
      width: 127px;
      height: 179px;
    }
  }
`

const BigRect = styled.div`
  position: absolute;
  left: 672px;
  top: 149px;
  width: 134px;
  height: 118px;
  background: url('${bigRectImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    left: 525px;
    top: -38px;
    width: 215px;
    height: 190px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    left: 488px;
    top: 114px;
    width: 97px;
    height: 86px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    left: 344px;
    top: -119px;
    width: 94px;
    height: 83px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    width: 73px;
    height: 64px;
    left: 0;
    top: -379px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 50px;
    height: 44px;
    left: 20px;
    top: -106px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 29px;
    height: 26px;
    left: 100%;
    top: 0;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    display: none;
  }
`

const SliderWrap = styled.div`
  position: relative;
  width: 427px;
  margin-left: 246px;
  z-index: 4;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 455px;
    margin-left: 144px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 390px;
    margin-left: 122px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 364px;
    margin-left: 116px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    position: absolute;
    width: 298px;
    left: 375px;
    top: 175px;
    margin-left: 0;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 226px;
    left: 206px;
    top: 120px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    top: 184px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    top: 164px;
  }
`

const YapanBlock = styled.div`
  display: flex;
  margin-left: 106px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    margin-left: 109px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    margin-left: 40px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    margin-left: 19px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    margin-left: 19px;
    margin-top: 60px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    margin-left: 0;
    margin-top: 44px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    margin-top: 0;
  }
`

const YapanRect = styled.div`
  width: 67px;
  height: 59px;
  background: url('${yapanRect}') center no-repeat;
  background-size: cover;
  margin-right: 18px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 71px;
    height: 63px;
    margin-right: 19px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 62px;
    height: 55px;
    margin-right: 8px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 41px;
    height: 36px;
    margin-right: 12px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 37px;
    height: 33px;
    margin-right: 8px;
  }
`

const YapanText = styled.div`
  font-family: 'ABCDiatype';
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ed1651;
  max-width: 211px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 12px;
    line-height: 15px;
    max-width: 195px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 11px;
    line-height: 14px;
    max-width: 171px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 8px;
    line-height: 10px;
    max-width: 135px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    max-width: 117px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 6px;
    line-height: 8px;
    max-width: 90px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    max-width: 84px;
  }
`
