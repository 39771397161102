import React, { useRef } from 'react'
import styled, { css } from 'styled-components'
import Slider from 'react-slick'
import SlideList from 'store/aboutSlides'
import { ReactComponent as PrevBtnImg } from 'img/new/about/prev-btn.svg'
import { ReactComponent as NextBtnImg } from 'img/new/about/next-btn.svg'
import controlsImg from 'img/new/about/controls-img.svg'
import sliderTitleBg from 'img/new/about/slider-title.svg'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const AboutSlider: React.FunctionComponent = () => {
  const sliderRef = useRef<any>(null)
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    arrows: false,
    vertical: true,
    verticalSwiping: true,
    responsive: [
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          infinite: true,
          vertical: false,
          verticalSwiping: false,
          swipe: true,
          variableWidth: true,
        },
      },
    ],
  }

  const onNextClick = () => {
    sliderRef.current.slickNext()
  }

  const onPrevClick = () => {
    sliderRef.current.slickPrev()
  }

  const onSlideClick = (e: any) => {
    let slideId =
      e.target.parentNode.parentNode.parentNode.getAttribute('data-index')
    sliderRef.current.slickGoTo(slideId)
  }

  return (
    <Root>
      <Slider ref={sliderRef} {...settings}>
        {SlideList.map((item, idx) => {
          return (
            <Slide onClick={onSlideClick} key={idx}>
              <SlideCont>
                {' '}
                <SlideBg src={item.img} />
                <SliderTitle>
                  <SliderTitleText>
                    {item.title}
                    <span>{item.titleSpan}</span>
                  </SliderTitleText>
                </SliderTitle>
                <SliderText>{item.text}</SliderText>
              </SlideCont>
            </Slide>
          )
        })}
      </Slider>
      <Controls>
        <NextBtn onClick={onNextClick}>
          <NextBtnImg />
        </NextBtn>
        <PrevBtn onClick={onPrevClick}>
          <PrevBtnImg />
        </PrevBtn>
      </Controls>
    </Root>
  )
}

export default AboutSlider

const Slide = styled.div`
  width: 427px;
  height: 308px;
  margin: 30px 0;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 455px;
    height: 328px;
    margin: 10px 0;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 390px;
    height: 281px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 364px;
    height: 262px;
    margin: 16px 0;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    width: 298px;
    height: 215px;
    margin: 16px 0;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 226px;
    height: 163px;
    margin: 0;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 100%;
    height: 200px;
    margin: 0 auto;
  }
`

const SlideBg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const SlideCont = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 29px 27px;
  background: rgba(39, 37, 35, 0.78);
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    padding: 26px 24px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    padding: 23px 20px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    padding: 18px 16px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 277px;
    height: 200px;
    margin: 0 auto;
    padding: 18px 17px;
  }
`

const SliderTitle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 358px;
  height: 64px;
  margin-bottom: 33px;
  background: url('${sliderTitleBg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 373px;
    height: 67px;
    margin-bottom: 33px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 320px;
    height: 58px;
    margin-bottom: 28px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 296px;
    height: 53px;
    margin-bottom: 38px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    width: 242px;
    height: 44px;
    margin-bottom: 26px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 184px;
    height: 33px;
    margin-bottom: 23px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 227px;
    height: 41px;
    margin-bottom: 20px;
  }
`

const SliderTitleText = styled.div`
  position: relative;
  font-family: 'ABCDiatype';
  font-weight: 400;
  font-size: 23px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #fff0cf;
  padding-left: 30px;
  max-width: 230px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 21px;
    padding-left: 30px;
    max-width: 200px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 19px;
    padding-left: 19px;
    max-width: 180px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 17px;
    padding-left: 18px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    font-size: 12px;
    padding-left: 15px;
    max-width: 152px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 9px;
    padding-left: 14px;
    max-width: 114px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    font-size: 15px;
    padding-left: 15px;
    max-width: 142px;
  }
  span {
    color: #2b2a28;
  }
`

const SliderText = styled.div`
  position: relative;
  font-family: 'ABCDiatypeMono';
  font-weight: 400;
  font-size: 16px;
  line-height: 126%;
  letter-spacing: 0.07em;
  color: #fff0cf;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 15px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 13px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    font-size: 11px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 8px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    font-size: 11px;
  }
`

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 968px;
  z-index: 4;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    height: 975px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    height: 847px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    height: 881px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    height: 705px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    height: 512px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    height: 200px;
  }
  div {
    outline: none;
  }
  .slick-list {
    padding: 0 !important;
    ${({ theme }) => theme.adaptive.newDesign.sm} {
      padding: 0px 50px !important;
      overflow: unset;
    }
  }
  .slick-slide {
    transform: scale(0.85);
    transition: 0.4s;
    cursor: grab;
    pointer-events: none;
  }
  .slick-active {
    opacity: 1;
    pointer-events: auto;
  }
  .slick-current {
    transform: scale(1);
    ${Slide} {
      box-shadow: 0px -5px 29px rgba(140, 200, 188, 0.1);
    }
    ${({ theme }) => theme.adaptive.newDesign.sm} {
      ${SlideBg} {
        box-shadow: 0px -5px 29px rgba(140, 200, 188, 0.1);
      }
      ${Slide} {
        box-shadow: unset;
      }
    }
  }
`

const Controls = styled.div`
  position: absolute;
  top: calc(50% + 60px);
  transform: translateY(-50%);
  right: -121px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    top: calc(50% + 30px);
    right: -100px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    right: -84px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    right: -85px;
    top: calc(50% - 18px);
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    right: -77px;
    top: calc(50% + 16px);
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    right: -44px;
    top: calc(50% - 8px);
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    display: flex;
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    top: calc(100% + 30px);
  }
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 67px;
    height: 59px;
    background: url('${controlsImg}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.newDesign.slg} {
      width: 49px;
      height: 43px;
    }
    ${({ theme }) => theme.adaptive.newDesign.xmd} {
      width: 44px;
      height: 39px;
    }
    ${({ theme }) => theme.adaptive.newDesign.md} {
      width: 30px;
      height: 27px;
    }
    ${({ theme }) => theme.adaptive.newDesign.sm} {
      display: none;
    }
  }
`

const BtnCss = css`
  display: block;
  width: 40px;
  height: 40px;
  z-index: 4;
  outline: none;
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 30px;
    height: 30px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    width: 27px;
    height: 27px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 18px;
    height: 18px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 30px;
    height: 30px;
  }
  svg {
    transition: 0.4s;
    width: 100%;
    height: 100%;
    fill: #8cc8bc;
  }
  &:hover {
    svg {
      fill: #5cceb7;
    }
  }
`

const NextBtn = styled.button`
  ${BtnCss};
  margin-bottom: 130px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    margin-bottom: 125px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    margin-bottom: 98px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    margin-bottom: 88px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    margin-bottom: 61px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    margin-bottom: 0;
    margin-left: 19px;
    order: 2;
  }
`

const PrevBtn = styled.button`
  ${BtnCss};
`
