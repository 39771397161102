import React from 'react'
import styled, { css } from 'styled-components'
import roadmapBg from 'img/new/roadmap/bg.png'
import titleBg from 'img/new/roadmap/title-bg.png'
import plusImg from 'img/new/roadmap/plus.svg'
import phaseBg from 'img/new/roadmap/phase-bg.svg'
// import firstPhaseBg from 'img/new/roadmap/first-phase-bg.svg'
// import secondPhaseBg from 'img/new/roadmap/second-phase-bg.svg'
// import thirdPhaseBg from 'img/new/roadmap/third-phase-bg.svg'
// import fourthPhaseBg from 'img/new/roadmap/fourth-phase-bg.svg'
import { Container } from 'theme/theme'

const RoadMap: React.FunctionComponent = () => {
  return (
    <Root>
      <Container>
        <Row>
          <TitleWrap>
            <Plus />
            <Title>
              <TitleText>
                ROADMAP<JapanText>ロードマップ</JapanText>
              </TitleText>
            </Title>
          </TitleWrap>
          <PhasesCont>
            <Phase>
              <PhaseTitleWrapper>
                <PhaseTitle>Phase I</PhaseTitle>
                <PhaseTitleLine />
              </PhaseTitleWrapper>
              <PhaseSubTitle>
                Finalize MVP; SolanaPrime launchpad landing page, with the
                following pool functionalities:
              </PhaseSubTitle>
              <PhaseText>
                <p>PRIME token staking</p>
                <p>Fixed price pools</p>
                <p>
                  Access dimensions (i.e.Tier structure and random draw
                  mechanism)
                </p>
              </PhaseText>
            </Phase>
            <Phase>
              <PhaseTitleWrapper>
                <PhaseTitle>Phase II</PhaseTitle>
                <PhaseTitleLine />
                <PhaseTitleLine />
              </PhaseTitleWrapper>
              <PhaseSubTitle>
                Product alignment with Solana Ecosystem as a whole;
              </PhaseSubTitle>
              <PhaseText>
                <p>Private and Public IDOs</p>
                <p>Project incubation through NERV HQ Accelerator</p>
                <p>SPIX token distribution (i.e. staking rewards)</p>
              </PhaseText>
            </Phase>
            <Phase>
              <PhaseTitleWrapper>
                <PhaseTitle>Phase III</PhaseTitle>
                <PhaseTitleLine />
                <PhaseTitleLine />
                <PhaseTitleLine />
              </PhaseTitleWrapper>
              <PhaseSubTitle>SPIX’s own roadmap:</PhaseSubTitle>
              <PhaseText>
                <p>
                  Secondary market access (could be implemented in Phase II)
                </p>
                <p>
                  PRIME Insurance fund, liquid allocations, delegation, smart
                  tiers
                </p>
                <p>PRIME token buy back</p>
                <p>SPIX staking</p>
              </PhaseText>
            </Phase>
            <Phase>
              <PhaseTitleWrapper>
                <PhaseTitle>Phase IV</PhaseTitle>
                <PhaseTitleLine />
                <PhaseTitleLine />
                <PhaseTitleLine />
                <PhaseTitleLine />
              </PhaseTitleWrapper>
              <PhaseSubTitle>
                Development depending on community demand:
              </PhaseSubTitle>
              <PhaseText>
                <p>
                  Liquidity mining protocol (i.e. Orca / Solanax / Raydium pool
                  with USDC-PRIME, USDC-SPIX, or any other pair’s liquidity
                  provision)
                </p>
                <p>
                  Claim and burn characteristics of the PRIME pool and it’s
                  underlying tokens
                </p>
                <p>
                  PrimeDAO and governance structure (i.e. votes on fees, ticket
                  sizes, PrimeVC decisions, and lottery mechanisms for example)
                </p>
              </PhaseText>
            </Phase>
          </PhasesCont>
        </Row>
      </Container>
    </Root>
  )
}

export default RoadMap

const Root = styled.div`
  position: relative;
  height: 983px;
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    height: auto;
    padding-top: 100px;
    padding-bottom: 110px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    padding-top: 150px;
    padding-bottom: 150px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    padding-bottom: 100px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 983px;
    background: url('${roadmapBg}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.newDesign.xmd} {
      height: 100%;
    }
  }
  ${Container} {
    ${({ theme }) => theme.adaptive.newDesign.xmd} {
      width: 100%;
      padding: 0 0 0 40px;
    }
    ${({ theme }) => theme.adaptive.newDesign.sm} {
      padding: 0 30px;
    }
  }
`

const Row = styled.div`
  display: flex;
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    position: relative;
    display: block;
  }
`

const TitleWrap = styled.div`
  position: relative;
  margin-top: 199px;
  width: 605px;
  height: 426px;
  background: url('${titleBg}') center no-repeat;
  background-size: contain;
  margin-left: -61px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 511px;
    height: 359px;
    margin-left: -53px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 435px;
    height: 306px;
    margin-left: -46px;
    margin-top: 160px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 327px;
    height: 224px;
    margin-left: -31px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    margin-top: 0;
    position: absolute;
    width: 370px;
    height: 258px;
    left: 0;
    top: 36px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 50%;
    height: 221px;
    background: url('${titleBg}') left bottom no-repeat;
    background-size: 90%;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 253px;
    height: 173px;
    position: relative;
    margin-bottom: 80px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    margin: 0 auto;
    left: -12px;
    margin-bottom: 75px;
  }
`

const Title = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 372px;
  left: 143px;
  width: 479px;
  height: 127px;
  background: #ed1651;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 405px;
    height: 107px;
    top: 306px;
    left: 115px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 351px;
    height: 90px;
    top: 251px;
    left: 100px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 259px;
    height: 67px;
    top: 190px;
    left: 80px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    top: 220px;
    left: 130px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    left: 70px;
    top: unset;
    bottom: -25px;
    width: 75%;
    height: 52px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 200px;
    height: 52px;
    left: 50px;
  }
`

const TitleText = styled.div`
  font-family: 'PressStart2P';
  font-weight: 400;
  font-size: 56px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #fff0cf;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 46px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 40px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 32px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 26px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    font-size: 23px;
  }
`

const JapanText = styled.div`
  font-family: 'ABCDiatype';
  position: absolute;
  right: -32px;
  top: 0;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  color: #ed1651;
  writing-mode: vertical-lr;
  vertical-align: middle;
  white-space: nowrap;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 16px;
    line-height: 19px;
    right: -25px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 14px;
    right: -22px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 10px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 8px;
    right: -18px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    font-size: 7px;
  }
`

const Plus = styled.div`
  position: absolute;
  top: -90px;
  left: 53px;
  width: 31px;
  height: 31px;
  background: url('${plusImg}') center no-repeat;
  background-size: contain;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 30px;
    height: 30px;
    left: 45px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 23px;
    height: 23px;
    top: -75px;
    left: 34px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    width: 20px;
    height: 20px;
    top: -72px;
    left: 34px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 18px;
    height: 18px;
    top: -72px;
    left: 34px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    top: -41px;
    left: 21px;
  }
`

const PhasesCont = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 985px;
  padding-top: 203px;
  padding-left: 165px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    padding-left: 142px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    padding-left: 110px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 800px;
    padding-left: 110px;
    padding-top: 182px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    width: 100%;
    padding-left: 0;
    padding-top: 60px;
  }
`

const Phase = styled.div`
  margin-bottom: 55px;
  max-width: 370px;
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    max-width: 350px;
    margin-bottom: 90px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    max-width: 315px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    margin-bottom: 60px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    margin-bottom: 0;
    max-width: 33.3333%;
    padding-right: 35px;
    &:first-child {
      margin-left: 430px;
      max-width: 285px;
      margin-bottom: 92px;
    }
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    max-width: 50%;
    margin-bottom: 35px;
    &:first-child {
      max-width: 50%;
      margin-left: 50%;
      margin-bottom: 65px;
    }
    &:last-child {
      max-width: 100%;
      margin-bottom: 0;
    }
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    max-width: 100% !important;
    margin-bottom: 30px !important;
    padding-right: 0;
    &:first-child {
      margin-left: 0;
    }
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    margin-bottom: 42px !important;
  }
`

const PhaseTitleWrapper = styled.div`
  display: flex;
  height: 59px;
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    height: 44px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    height: 39px;
  }
`

const PhaseTitle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-family: 'ABCDiatype';
  font-weight: 700;
  font-size: 26px;
  line-height: 33px;
  color: #fff0cf;
  z-index: 2;
  height: 100%;
  width: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  background-image: url(${phaseBg});
  padding-left: 12px;
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    font-size: 19px;
    line-height: 24px;
    width: 111px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    font-size: 17px;
    width: 98px;
    padding-left: 9px;
  }
`

const PhaseTitleLine = styled.div`
  position: relative;
  top: 6px;
  background: #8cc8bc;
  width: 15px;
  height: 48px;
  transform: skewX(32deg);
  margin-left: 10px;
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    top: 4px;
    width: 12px;
    height: 36px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    height: 32px;
    margin-left: 7px;
  }
  &:nth-child(2) {
    margin-left: -5px;
    ${({ theme }) => theme.adaptive.newDesign.sm} {
      margin-left: -3px;
    }
  }
`

const PhaseSubTitle = styled.div`
  position: relative;
  font-family: 'ABCDiatypeMono';
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #4f4943;
  margin-top: 34px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    margin-top: 28px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    font-size: 14px;
    line-height: 18px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    margin-top: 21px;
  }
`

const PhaseText = styled.div`
  position: relative;
  font-family: 'ABCDiatypeMono';
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #4f4943;
  margin-top: 24px;
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 15px;
    line-height: 19px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    font-size: 13px;
    line-height: 16px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    margin-top: 16px;
  }
  p {
    margin: 0;
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`
