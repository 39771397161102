import React, { MouseEvent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import gsap from 'gsap'
import titleImg from 'img/slider/back/eva-00.svg'
import logoImg from 'img/slider/back/logo.svg'
import useWindowSize from 'helpers/utils/useWindowSize'

interface SlideProps {
  image: string
  id: number
  realId: number
  activeSlide: number
  slideVisible: number
  goToSlide: number
  onTransformEnd: (id: number) => void
  onSlideClick: (id: number) => void
  isShowAll: boolean
  turnOver: number
}

const transformFunc = (
  translateY: string,
  translateX: string,
  scale: number
) => {
  return `translateY(${translateY}) translateX(${translateX})  scale(${scale})`
}

const Slide: React.FunctionComponent<SlideProps> = (props) => {
  const { width } = useWindowSize()
  const SlideRef = useRef(null)
  const [visibleVal, setVisibleVal] = useState(width < 580 ? 0 : 2)
  const [resizeTimeout, setReszieTimeout] = useState<any>(null)
  const [stylesTransform, setStylesTransform] = useState<any>({
    transform:
      props.id < props.activeSlide
        ? transformFunc(
            '-50%',
            `-${(props.activeSlide - props.id + 1.5) * 50}%`,
            1.34 - (3 * (props.activeSlide - props.id)) / 10
          )
        : props.id > props.activeSlide
        ? transformFunc(
            '-50%',
            `${(props.id - props.activeSlide - 0.5) * 50}%`,
            1.34 - (3 * (props.id - props.activeSlide)) / 10
          )
        : transformFunc('-50%', '-50%', 1.34),

    left: '50%',
    zIndex:
      props.id < props.activeSlide
        ? 5 - (props.activeSlide - props.id)
        : props.id > props.activeSlide
        ? 5 - (props.id - props.activeSlide)
        : 5,
    pointerEvents:
      props.id < props.activeSlide - visibleVal ||
      props.id > props.activeSlide + visibleVal
        ? 'none'
        : 'auto',
    opacity:
      props.id < props.activeSlide - visibleVal ||
      props.id > props.activeSlide + visibleVal
        ? 0
        : 1,
  })

  useEffect(() => {
    Transform(SlideRef.current, props.activeSlide, 0)
  }, [visibleVal])

  useEffect(() => {
    function handleResize() {
      clearTimeout(resizeTimeout)
      setReszieTimeout(
        setTimeout(() => {
          if (window.innerWidth < 580) setVisibleVal(0)
          else setVisibleVal(2)
        }, 100)
      )
    }
    // Add event listener
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (props.goToSlide !== 0) {
      Transform(SlideRef.current, props.goToSlide, 0.3)
    }
  }, [props.goToSlide])

  useEffect(() => {
    if (props.isShowAll) {
      if (
        props.id >= props.activeSlide - visibleVal &&
        props.id <= props.activeSlide + visibleVal
      ) {
        ShowAll(SlideRef.current)
      }
    } else {
      Transform(SlideRef.current, props.activeSlide, 0.3)
    }
  }, [props.isShowAll])

  const ShowAll = (elem: gsap.TweenTarget) => {
    let leftVal = `${(props.realId - 1) * 20}%`

    gsap.fromTo(elem, stylesTransform, {
      duration: 0.4,
      transform: 'translateY(-50%) scale(0.9)',
      left: leftVal,
      ease: 'circ.inOut',
      pointerEvents: 'auto',
      onComplete: () => {
        setStylesTransform({
          ...stylesTransform,
          transform: 'translateY(-50%) scale(0.9)',
          left: leftVal,
          pointerEvents: 'auto',
        })
        props.onTransformEnd(props.activeSlide)
      },
    })
  }

  const Transform = (
    elem: gsap.TweenTarget,
    newSlide: number,
    duration: number
  ) => {
    let transformString = ''
    let opacityVal = 0
    let zIndexVal = 0
    let pointerEventsVal = ''
    if (props.id < newSlide) {
      transformString = transformFunc(
        '-50%',
        `-${(newSlide - props.id + 1.5) * 50}%`,
        1.34 - (3 * (newSlide - props.id)) / 10
      )
    } else if (props.id > newSlide) {
      transformString = transformFunc(
        '-50%',
        `${(props.id - newSlide - 0.5) * 50}%`,
        1.34 - (3 * (props.id - newSlide)) / 10
      )
    } else {
      transformString = transformFunc('-50%', '-50%', 1.34)
    }
    opacityVal =
      props.id < newSlide - visibleVal || props.id > newSlide + visibleVal
        ? 0
        : 1
    pointerEventsVal =
      props.id < newSlide - visibleVal || props.id > newSlide + visibleVal
        ? 'none'
        : 'auto'
    zIndexVal =
      props.id < newSlide
        ? 5 - (newSlide - props.id)
        : props.id > newSlide
        ? 5 - (props.id - newSlide)
        : 5

    gsap.fromTo(elem, stylesTransform, {
      duration:
        duration === 0
          ? 0
          : duration + Math.abs(newSlide - props.activeSlide) * 0.1,
      transform: transformString,
      opacity: opacityVal,
      zIndex: zIndexVal,
      pointerEvents: pointerEventsVal,
      left: '50%',
      ease: 'circ.inOut',
      onComplete: () => {
        setStylesTransform({
          ...stylesTransform,
          opacity: opacityVal,
          transform: transformString,
          zIndex: zIndexVal,
          left: '50%',
        })
        if (newSlide >= 11) {
          Transform(SlideRef.current, newSlide - 5, 0)
        } else if (newSlide <= 4) {
          Transform(SlideRef.current, newSlide + 5, 0)
        } else {
          props.onTransformEnd(newSlide)
        }
      },
    })
  }

  useEffect(() => {
    if (props.turnOver !== 0) {
      rotateSlide(SlideRef.current)
    }
  }, [props.turnOver])

  const onSlideClick = () => {
    // if (props.isShowAll) {
    //   rotateSlide(SlideRef.current)
    // } else {
    //   if (props.id !== props.activeSlide) {
    //     props.onSlideClick(props.id)
    //   } else {
    //     rotateSlide(SlideRef.current)
    //   }
    // }
    if (props.id !== props.activeSlide && !props.isShowAll) {
      props.onSlideClick(props.id)
    }
  }

  const rotateSlide = (elem: gsap.TweenTarget) => {
    gsap.to(elem, {
      duration: 0.4,
      transform: `${stylesTransform.transform} rotateY(180deg)`,
      onComplete: () => {
        setStylesTransform({
          ...stylesTransform,
          transform: `${stylesTransform.transform} rotateY(180deg)`,
        })
      },
    })
  }

  return (
    <Root
      onClick={onSlideClick}
      ref={SlideRef}
      style={{ ...stylesTransform }}
      idx={props.id}
    >
      <CardFront
        style={{
          backgroundImage: `url(${props.image})`,
          backgroundSize: 'cover',
        }}
      />
      <CardBack>
        <CardHeader>
          <Title />
          <Logo />
        </CardHeader>
      </CardBack>
    </Root>
  )
}

export default Slide

interface StyleSlideProps {
  idx: number
}

const Root = styled.div<StyleSlideProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 300px;
  flex-shrink: 0;
  border-radius: 10px;
  transition-property: transform, opacity, height;
  perspective: 1000px;
  transform-style: preserve-3d;
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 196px;
    height: 295px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 171px;
    height: 257px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    width: 158px;
    height: 237px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 100px;
    height: 150px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 128px;
    height: 192px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    width: 164px;
    height: 246px;
  }
`

const CardFront = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
`

const CardBack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background: #252323;
  transform: rotateY(180deg);
  padding: 23px 22px;
`

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const Title = styled.div`
  width: 84px;
  height: 44px;
  background: url('${titleImg}') center no-repeat;
  background-size: cover;
`

const Logo = styled.div`
  width: 158px;
  height: 12px;
  background: url('${logoImg}') center no-repeat;
  background-size: cover;
  margin-right: 6px;
`
