import React from 'react'
import styled, { css } from 'styled-components'
import arrowImg from 'img/new/header/arrow.svg'
import { Container } from 'theme/theme'
import {
  APPLY_LINK,
  DECK,
  DISCORD_LINK,
  FAQ_LINK,
  MEDIUM_LINK,
  TELEGRAM_LINK,
  TWITTER_LINK,
} from 'configs/app'

interface modalProps {
  openModal: () => void
}

const Header: React.FunctionComponent<modalProps> = (props) => {
  return (
    <Root>
      <Container>
        <NavItem href={APPLY_LINK} target={'_blank'}>
          <NavItemText>apply</NavItemText>
        </NavItem>
        <NavItem>
          <NavItemText isDropDown={true}>JOIN</NavItemText>
          <DropDownWrap>
            <NavDropDown>
              <DropDownLink href={TELEGRAM_LINK} target={'_blank'}>
                telegram
              </DropDownLink>
              <DropDownLink href={DISCORD_LINK} target={'_blank'}>
                Discord
              </DropDownLink>
              <DropDownLink href={TWITTER_LINK} target={'_blank'}>
                Twitter
              </DropDownLink>
              {/*<DropDownLink href={'/'} target={'_blank'}>*/}
              {/*  Youtube*/}
              {/*</DropDownLink>*/}
              <DropDownLink href={MEDIUM_LINK} target={'_blank'}>
                medium
              </DropDownLink>
            </NavDropDown>
          </DropDownWrap>
        </NavItem>
        <NavItem href={MEDIUM_LINK} target={'_blank'}>
          <NavItemText>BLOG</NavItemText>
        </NavItem>
        <NavItem>
          <NavItemText isDropDown={true}>About</NavItemText>
          <DropDownWrap>
            <NavDropDown>
              <DropDownLink href={DECK} target={'_blank'}>
                Deck
              </DropDownLink>
              <DropDownLink href={FAQ_LINK} target={'_blank'}>
                FAQ
              </DropDownLink>
              <DropDownLink onClick={props.openModal}>Contact us</DropDownLink>
            </NavDropDown>
          </DropDownWrap>
        </NavItem>
      </Container>
    </Root>
  )
}

export default Header

const Root = styled.div`
  position: relative;
  padding-top: 77px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    padding-top: 62px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    padding-top: 66px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    padding-top: 46px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    padding-top: 50px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    padding-top: 32px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    padding-top: 26px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    padding-top: 25px;
  }
  ${Container} {
    display: flex;
    justify-content: flex-end;
    ${({ theme }) => theme.adaptive.newDesign.md} {
      display: none;
    }
  }
`

const DropDownWrap = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding-top: 10px;
  pointer-events: none;
`

const NavDropDown = styled.div`
  width: 100%;
  padding: 15px 12px;
  background: #8cc8bc;
  border-radius: 2px;
  transition: 0.4s;
  opacity: 0;
  pointer-events: none;
`

interface dropDownProps {
  isDropDown?: boolean
}

const NavItemText = styled.span<dropDownProps>`
  position: relative;
  display: block;
  font-family: 'ABCDiatypeMono';
  font-weight: 400;
  font-size: 16px;
  text-transform: uppercase;
  color: #8cc8bc;
  pointer-events: none;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 13px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    font-size: 11px;
  }
  &:before {
    ${({ isDropDown }) => {
      if (isDropDown)
        return css`
          content: '';
        `
    }}
    position: absolute;
    width: 8px;
    height: 4px;
    top: 50%;
    transform: translateY(-50%);
    right: -15px;
    background: url('${arrowImg}') center no-repeat;
    background-size: cover;
    transition: 0.4s;
  }
`

const NavItem = styled.a`
  position: relative;
  width: 103px;
  height: 32px;
  margin-right: 10px;
  background: rgba(140, 200, 188, 0.12);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 91px;
    height: 28px;
    margin-right: 10px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 83px;
    height: 28px;
    margin-right: 10px;
    border-radius: 3px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    width: 81px;
    height: 21px;
    border-radius: 2px;
  }
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    ${NavDropDown}, ${DropDownWrap} {
      opacity: 1;
      pointer-events: auto;
    }
    ${NavItemText} {
      &:before {
        transform: rotate(-180deg);
      }
    }
  }
`

const DropDownLink = styled.a`
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  text-transform: uppercase;
  color: #3a3734;
  margin-bottom: 16px;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
`
