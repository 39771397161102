import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import tiersTitle from 'img/new/tiers/title-wrap.svg'
import formulaImg from 'img/new/tiers/formula-img.svg'
import formulaImgXs from 'img/new/tiers/formula-img-xs.svg'
import controlsImg from 'img/new/tiers/controls-img.svg'
import numberImg from 'img/new/tiers/number-img.svg'
import curtainImg from 'img/new/tiers/curtain-left.svg'
import { ReactComponent as TurnOverBtnImg } from 'img/new/tiers/turn-over-btn.svg'
import minusImg from 'img/new/tiers/show-minus.svg'
import plusImg from 'img/new/tiers/show-plus.svg'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { LeftCurtainAnimation, RightCurtainAnimation } from './animation'
import Slider from './slider'
import { Container } from 'theme/theme'
import useWindowSize from 'helpers/utils/useWindowSize'

const Tiers: React.FunctionComponent = () => {
  const leftCurtainRef = useRef(null)
  const rightCurtainRef = useRef(null)
  const [isShowAll, setIsShowAll] = useState(false)
  const [turnOver, setTurnOver] = useState(0)
  const { width } = useWindowSize()
  gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {
    if (width > 580) {
      if (leftCurtainRef.current) {
        LeftCurtainAnimation(leftCurtainRef.current)
      }
      if (rightCurtainRef.current) {
        RightCurtainAnimation(rightCurtainRef.current)
      }
    }
  }, [])

  const showAll = () => {
    setIsShowAll(!isShowAll)
  }

  const turnOverAll = () => {
    setTurnOver(turnOver + 1)
  }

  return (
    <Root>
      <Container>
        <LeftCurtain ref={leftCurtainRef} />
        <RightCurtain ref={rightCurtainRef} />
        <TitleRow>
          <Title>
            <TitleText>
              Smart <span>tiers</span>
            </TitleText>
            <Formula />
          </Title>
        </TitleRow>
        <SliderWrap>
          <Slider isShowAll={isShowAll} isTurnOverAll={turnOver} />
        </SliderWrap>
        <ControlsRow>
          <ControlsImg />
          <Controls>
            {/*<ControlItem>*/}
            {/*  <ControlBtnWrap onClick={turnOverAll}>*/}
            {/*    <TurnOverButton>*/}
            {/*      <TurnOverBtnImg />*/}
            {/*    </TurnOverButton>*/}
            {/*  </ControlBtnWrap>*/}
            {/*  <ControlText>Turn over the cards</ControlText>*/}
            {/*</ControlItem>*/}
            <ControlItem>
              <ControlBtnWrap onClick={showAll}>
                <ShowAllBtn isShowAll={isShowAll} />
              </ControlBtnWrap>
              <ControlText>Show all tiers</ControlText>
            </ControlItem>
          </Controls>
        </ControlsRow>
        {/*{width < 580 && (*/}
        {/*  <Attention>*/}
        {/*    <AttentionImg>*/}
        {/*      <TurnOverBtn />*/}
        {/*    </AttentionImg>*/}
        {/*    <AttentionText>Tap on the card to turn over </AttentionText>*/}
        {/*  </Attention>*/}
        {/*)}*/}
      </Container>
    </Root>
  )
}

export default Tiers

const Root = styled.div`
  position: relative;
  margin-top: 113px;
  padding-bottom: 71px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    margin-top: 108px;
    padding-bottom: 66px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    margin-top: 122px;
    padding-bottom: 40px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    margin-top: 113px;
    padding-bottom: 40px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    margin-top: 74px;
    padding-bottom: 29px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    margin-top: 166px;
    padding-bottom: 27px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    margin-top: 36px;
    padding-bottom: 24px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    margin-top: 37px;
    padding-bottom: 35px;
  }
`

const CurtainCss = css`
  position: absolute;
  height: 100%;
  width: 1864px;
  z-index: 6;
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    width: 991px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    display: none;
  }
`

const LeftCurtain = styled.div`
  ${CurtainCss};
  top: 0;
  right: 30%;
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    right: 22%;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 615px;
    width: 100%;
    background: url('${curtainImg}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.newDesign.xmd} {
      height: 328px;
    }
  }
`

const RightCurtain = styled.div`
  ${CurtainCss};
  bottom: 0;
  //right: -1260px;
  left: 30%;
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    left: 22%;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 615px;
    width: 100%;
    background: url('${curtainImg}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.newDesign.xmd} {
      height: 328px;
    }
  }
`

const TitleRow = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Title = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 709px;
  height: 116px;
  background: url('${tiersTitle}') center no-repeat;
  background-size: contain;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 598px;
    height: 98px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 512px;
    height: 98px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 479px;
    height: 81px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    width: 392px;
    height: 67px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 295px;
    height: 54px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 218px;
    height: 40px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    width: 100%;
    height: 45px;
  }
`

const Formula = styled.div`
  position: absolute;
  left: -233px;
  top: 30px;
  width: 144px;
  height: 84px;
  background: url('${formulaImg}') center no-repeat;
  background-size: contain;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    left: -255px;
    top: 14px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    left: -221px;
    top: 19px;
    width: 118px;
    height: 69px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    left: -207px;
    top: 13px;
    width: 115px;
    height: 60px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    left: -170px;
    top: 10px;
    width: 99px;
    height: 58px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    left: -71px;
    top: 9px;
    width: 58px;
    height: 34px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    left: -79px;
    top: 4px;
    width: 50px;
    height: 36px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    left: 50%;
    top: unset;
    bottom: -28px;
    transform: translateX(-50%);
    width: 145px;
    height: 20px;
    background: url('${formulaImgXs}') center no-repeat;
    background-size: contain;
  }
`

const TitleText = styled.div`
  font-family: 'PressStart2P';
  font-weight: 400;
  font-size: 54px;
  line-height: 100%;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  color: #ed1651;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 44px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 38px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 34px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    font-size: 28px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 22px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    font-size: 18px;
  }
  span {
    color: #8cc8bc;
  }
`

const SliderWrap = styled.div`
  display: flex;
  align-items: center;
  width: 1154px;
  height: 401px;
  margin: 131px auto 0;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 1111px;
    height: 379px;
    margin: 122px auto 0;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 1090px;
    height: 331px;
    margin: 126px auto 0;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 940px;
    height: 294px;
    margin: 100px auto 0;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    width: 100%;
    height: 259px;
    margin: 102px auto 0;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    height: 193px;
    margin: 49px auto 0;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    height: 263px;
    margin: 34px auto 0;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    height: 334px;
    margin: 103px auto 0;
  }
`

const ControlsRow = styled.div`
  display: flex;
  margin-top: 57px;
  align-items: center;
  z-index: 2;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    margin-top: 66px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    margin-top: 90px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    align-items: unset;
    margin-top: 143px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    margin-top: 88px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    margin-top: 100px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    margin-top: 106px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    margin-top: 148px;
  }
`

const ControlsImg = styled.div`
  position: relative;
  width: 541px;
  height: 50px;
  background: url('${controlsImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 425px;
    height: 39px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 388px;
    height: 36px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 343px;
    height: 32px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    width: 207px;
    height: 28px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 138px;
    height: 19px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 118px;
    height: 18px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    width: 130px;
    height: 25px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 181px;
    height: 53px;
    top: -75px;
    left: 0;
    background: url('${numberImg}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.newDesign.xl} {
      width: 142px;
      height: 42px;
      top: -66px;
    }
    ${({ theme }) => theme.adaptive.newDesign.lg} {
      width: 121px;
      height: 36px;
      top: -62px;
    }
    ${({ theme }) => theme.adaptive.newDesign.slg} {
      width: 121px;
      height: 36px;
      top: -54px;
    }
    ${({ theme }) => theme.adaptive.newDesign.xmd} {
      width: 94px;
      height: 28px;
      top: -39px;
    }
    ${({ theme }) => theme.adaptive.newDesign.md} {
      width: 69px;
      height: 20px;
      top: -29px;
    }
    ${({ theme }) => theme.adaptive.newDesign.sm} {
      width: 48px;
      height: 14px;
      top: -24px;
    }
    ${({ theme }) => theme.adaptive.newDesign.xsm} {
      width: 58px;
      height: 17px;
      top: -27px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`

const Controls = styled.div`
  display: flex;
  margin-left: 120px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    margin-left: 105px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    margin-left: 100px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    margin-left: 78px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    margin-left: 90px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    display: block;
    margin-left: 76px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    display: none;
  }
`

const ControlItem = styled.div`
  margin-right: 30px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    margin-right: 30px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    margin-right: 14px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    margin-right: 30px;
    margin-top: -100px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    margin-right: 30px;
    margin-top: -27px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    margin-right: 0;
    margin-bottom: 10px;
    margin-top: -63px;
    &:last-child {
      margin: 0px;
    }
  }
  &:last-child {
    margin-bottom: 0px;
  }
`

const ControlBtnWrap = styled.div`
  display: flex;
  align-items: center;
  width: 32px;
  height: 34px;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 27px;
    height: 29px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 19px;
    height: 20px;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`

const TurnOverButton = styled.button`
  width: 100%;
  height: 100%;
`

interface btnProps {
  isShowAll: boolean
}

const ShowAllBtn = styled.button<btnProps>`
  position: relative;
  display: block;
  width: 100%;
  height: 14px;
  border: 1px solid rgba(255, 240, 207, 0.24);
  border-radius: 7px;
  transition: 0.4s;
  background: ${({ isShowAll }) => (isShowAll ? '#303032' : '')};
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    height: 12px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    height: 8px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: ${({ isShowAll }) => (isShowAll ? css`calc(100% - 11px)` : '2px')};
    width: 9px;
    height: 9px;
    background: ${({ isShowAll }) =>
        isShowAll ? `url('${minusImg}')` : `url('${plusImg}')`}
      center no-repeat;
    background-size: cover;
    transition: 0.4s;
    ${({ theme }) => theme.adaptive.newDesign.slg} {
      width: 8px;
      height: 8px;
      left: ${({ isShowAll }) => (isShowAll ? css`calc(100% - 10px)` : '2px')};
    }
    ${({ theme }) => theme.adaptive.newDesign.md} {
      width: 5px;
      height: 5px;
      left: ${({ isShowAll }) => (isShowAll ? css`calc(100% - 6px)` : '1px')};
    }
  }
`

const ControlText = styled.div`
  font-weight: 200;
  font-size: 15px;
  line-height: 120%;
  text-align: center;
  color: rgba(255, 240, 207, 0.24);
  margin-top: 9px;
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 127px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 91px;
    font-size: 7px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    width: 76px;
    font-size: 9px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 70px;
    font-size: 6px;
  }
`

const Attention = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 357px;
  left: 50%;
  transform: translateX(-50%);
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    top: 95px;
  }
`

const AttentionText = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 120%;
  color: #fff0cf;
  opacity: 0.24;
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    width: 170px;
  }
`

const AttentionImg = styled.div`
  width: 31px;
  height: 33px;
  margin-right: 15px;
  svg {
    width: 100%;
    height: 100%;
  }
`
