import { createGlobalStyle } from 'styled-components'
import HemicubeWoff from './fonts/Hemicube_PERSONAL_USE_ONLY.woff'
import HemicubeWoff2 from './fonts/Hemicube_PERSONAL_USE_ONLY.woff2'
import ABCDiatypeBoldWoff from './fonts/ABCDiatype-Bold.woff'
import ABCDiatypeBoldWoff2 from './fonts/ABCDiatype-Bold.woff2'
import ABCDiatypeMediumWoff from './fonts/ABCDiatype-Medium.woff'
import ABCDiatypeMediumWoff2 from './fonts/ABCDiatype-Medium.woff2'
import ABCDiatypeRegularWoff from './fonts/ABCDiatype-Regular.woff'
import ABCDiatypeRegularWoff2 from './fonts/ABCDiatype-Regular.woff2'
import ABCDiatypeMonoLightWoff from './fonts/ABCDiatypeMono-Light.woff'
import ABCDiatypeMonoLightWoff2 from './fonts/ABCDiatypeMono-Light.woff2'
import ABCDiatypeMonoLightItalicWoff from './fonts/ABCDiatypeMono-LightItalic.woff'
import ABCDiatypeMonoLightItalicWoff2 from './fonts/ABCDiatypeMono-LightItalic.woff2'
import ABCDiatypeMonoRegularWoff from './fonts/ABCDiatypeMono-Regular.woff'
import ABCDiatypeMonoRegularWoff2 from './fonts/ABCDiatypeMono-Regular.woff2'
import NotoSansBoldWoff from './fonts/NotoSansJP-Bold.woff'
import NotoSansBoldWoff2 from './fonts/NotoSansJP-Bold.woff2'
import PressStart2PRegularWoff from './fonts/PressStart2P-Regular.woff'
import PressStart2PRegularWoff2 from './fonts/PressStart2P-Regular.woff2'

// Neue Haas Display Display Pro
const hemicube = {
  medium: {
    woff2: HemicubeWoff2,
    woff: HemicubeWoff,
  },
}

const pressStart = {
  regular: {
    woff2: PressStart2PRegularWoff2,
    woff: PressStart2PRegularWoff,
  },
}

const NotoSans = {
  bold: {
    woff2: NotoSansBoldWoff2,
    woff: NotoSansBoldWoff,
  },
}

const ABCDiatype = {
  medium: {
    woff2: ABCDiatypeMediumWoff2,
    woff: ABCDiatypeMediumWoff,
  },
  regular: {
    woff2: ABCDiatypeRegularWoff2,
    woff: ABCDiatypeRegularWoff,
  },
  bold: {
    woff2: ABCDiatypeBoldWoff2,
    woff: ABCDiatypeBoldWoff,
  },
}

const ABCDiatypeMono = {
  light: {
    woff2: ABCDiatypeMonoLightWoff2,
    woff: ABCDiatypeMonoLightWoff,
  },
  lightItalic: {
    woff2: ABCDiatypeMonoLightItalicWoff2,
    woff: ABCDiatypeMonoLightItalicWoff,
  },
  regular: {
    woff2: ABCDiatypeMonoRegularWoff2,
    woff: ABCDiatypeMonoRegularWoff,
  },
}

const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'Hemicube';
    src: local('Hemicube'),
    url(${hemicube.medium.woff2}) format('woff2'),
    url(${hemicube.medium.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'ABCDiatype';
    src: local('ABCDiatype'),
    url(${ABCDiatype.medium.woff2}) format('woff2'),
    url(${ABCDiatype.medium.woff}) format('woff');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'ABCDiatype';
    src: local('ABCDiatype'),
    url(${ABCDiatype.regular.woff2}) format('woff2'),
    url(${ABCDiatype.regular.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'ABCDiatype';
    src: local('ABCDiatype'),
    url(${ABCDiatype.bold.woff2}) format('woff2'),
    url(${ABCDiatype.bold.woff}) format('woff');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'ABCDiatypeMono';
    src: local('ABCDiatypeMono'),
    url(${ABCDiatypeMono.light.woff2}) format('woff2'),
    url(${ABCDiatypeMono.light.woff}) format('woff');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'ABCDiatypeMono';
    src: local('ABCDiatypeMono'),
    url(${ABCDiatypeMono.regular.woff2}) format('woff2'),
    url(${ABCDiatypeMono.regular.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'NotoSans';
    src: local('NotoSans'),
    url(${NotoSans.bold.woff2}) format('woff2'),
    url(${NotoSans.bold.woff}) format('woff');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'PressStart2P';
    src: local('PressStart2P'),
    url(${pressStart.regular.woff2}) format('woff2'),
    url(${pressStart.regular.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
`
export default Fonts
