import React, { useState } from "react";
import styled, { css } from "styled-components";
import contactImg from "../../img/forms/contact-img.svg";
import closeImg from "../../img/forms/close-img.svg";
import successImg from "../../img/forms/success-img.svg";
import successTitleImg from "../../img/forms/success-title-img.svg";
import successBtn from "../../img/forms/success-btn.svg";
import successBtnSm from "../../img/forms/success-btn-sm.svg";
import successBtnXs from "../../img/forms/success-btn-xs.svg";
import Send from "../icons/Send";
import useWindowSize from "../../helpers/utils/useWindowSize";
import SendSm from "../icons/SendSm";
import SendXs from "../icons/SendXs";
import { has, clone } from "lodash";
import { sendEmail } from "../../helpers/sendEmail";
import { SendEmailErrors } from "../../types/mail/mailTypes";

export interface ModalProps {
  onClose(): void;
}

const DEFAULT_ERRORS_STATE: SendEmailErrors = {
  name: false,
  email: false,
  message: false,
};

interface ErrorProps {
  error?: boolean;
}

const ContactForm: React.FunctionComponent<ModalProps> = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [fieldErrors, setFieldErrors] = useState(DEFAULT_ERRORS_STATE);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const { width } = useWindowSize();

  const error = <Error>{errorMessage}</Error>;

  const resetForm = () => {
    setMessage("");
    setName("");
    setEmail("");
    setFieldErrors(DEFAULT_ERRORS_STATE);
    setErrorMessage("");
  };

  const handleSubmit = async () => {
    setIsSending(true);
    const response = await sendEmail({ name, email, message });
    setIsSending(false);
    if (response.error) {
      console.log(response);
      setErrorMessage(response.error.details[0].message);
      const defaultState = clone(DEFAULT_ERRORS_STATE);
      const errorField: keyof SendEmailErrors =
        response.error.details[0].context.key;
      if (has(fieldErrors, errorField)) {
        defaultState[errorField] = true;
        setFieldErrors({ ...defaultState });
      }
      return;
    }
    setIsSuccess(true);
    setTimeout(() => {
      setIsSuccess(false);
    }, 5000);
    resetForm();
  };

  return (
    <Form>
      <Title />
      <Field error={fieldErrors.name}>
        <TopLine>
          <Label>Name</Label>
          {error}
        </TopLine>
        <Input
          type="text"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
      </Field>
      <Field error={fieldErrors.email}>
        <TopLine>
          <Label>Email address</Label>
          {error}
        </TopLine>
        <Input
          type="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
      </Field>
      <Field error={fieldErrors.message}>
        <TopLine>
          <Label>Message</Label>
          {error}
        </TopLine>
        <TextArea
          value={message}
          onChange={(event) => setMessage(event.target.value)}
        />
      </Field>
      <Buttons>
        <SendWrapper onClick={handleSubmit} disabled={isSending}>
          Send message
          <SendBg>
            {width <= 800 && width > 360 ? (
              <SendSm />
            ) : width <= 360 ? (
              <SendXs />
            ) : (
              <Send />
            )}
          </SendBg>
        </SendWrapper>
        <Close onClick={props.onClose} />
      </Buttons>
      <Success active={isSuccess}>
        <SuccessTitle />
        <SuccessText>
          Thank you for contacting us. We will get back to you as soon as
          possible.
        </SuccessText>
        <SuccessImg />
        <Buttons>
          <SuccessBtn />
          <Close onClick={props.onClose} />
        </Buttons>
      </Success>
    </Form>
  );
};

export default ContactForm;

export interface SuccessProps {
  active?: boolean;
}

const Form = styled.div`
  width: 856px;
  height: 741px;
  background: #fff0cf;
  padding: 69px 76px 0;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 727px;
    height: 629px;
    padding: 59px 64px 0;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 665px;
    height: 576px;
    padding: 54px 59px 0;
  }
  ${({ theme }) => theme.adaptive.modal.md} {
    width: 532px;
    height: 460px;
    padding: 43px 46px 0;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 532px;
    height: 460px;
    padding: 43px 46px 0;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 396px;
    height: 597px;
    padding: 43px 40px 0;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    width: 100vw;
    height: 547px;
    padding: 45px 40px 0;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    height: 342px;
    padding: 28px 30px 0;
  }
`;

const Title = styled.div`
  width: 100%;
  margin-bottom: 50px;
  background: url("${contactImg}") center no-repeat;
  height: 51px;
  ${({ theme }) => theme.adaptive.main.xl} {
    height: 43px;
    margin-bottom: 47px;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    height: 40px;
    margin-bottom: 39px;
  }
  ${({ theme }) => theme.adaptive.modal.md} {
    height: 32px;
    margin-bottom: 31px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    height: 32px;
    margin-bottom: 31px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    height: 22px;
    margin-bottom: 36px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    height: 21px;
    margin-bottom: 37px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    height: 19px;
    margin-bottom: 22px;
  }
`;

const Label = styled.div`
  font-family: "ABCDiatypeMono";
  font-weight: 300;
  font-size: 18px;
  line-height: 130%;
  color: #252323;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.modal.md} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    font-size: 12px;
  }
`;

const Error = styled(Label)`
  color: #ff2e2e;
  ${({ theme }) => theme.adaptive.main.sm} {
    font-size: 8px;
  }
`;

const Field = styled.div<ErrorProps>`
  margin-bottom: 33px;
  ${({ theme }) => theme.adaptive.main.xl} {
    margin-bottom: 28px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    margin-bottom: 26px;
  }
  ${({ theme }) => theme.adaptive.modal.md} {
    margin-bottom: 21px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    margin-bottom: 21px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    margin-bottom: 24px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    margin-bottom: 25px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    margin-bottom: 17px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  ${Error} {
    opacity: ${({ error }) => (error ? "1" : 0)};
  }
`;

const TopLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  ${({ theme }) => theme.adaptive.main.xl} {
    margin-bottom: 7px;
  }
  ${({ theme }) => theme.adaptive.modal.md} {
    margin-bottom: 5px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    margin-bottom: 5px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    margin-bottom: 8px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    margin-bottom: 10px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    margin-bottom: 8px;
  }
`;

const InputsCss = css`
  font-family: "ABCDiatypeMono";
  width: 100%;
  padding: 12px 24px;
  font-size: 18px;
  line-height: 130%;
  background: #f2e3c1;
  font-weight: 300;
  color: #252323;
  opacity: 0.4;
  border: 0px;
  border-radius: 0;
  outline: none;
  ${({ theme }) => theme.adaptive.main.xl} {
    padding: 12px 20px;
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    padding: 10px 20px;
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.modal.md} {
    padding: 8px 16px;
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    padding: 8px 16px;
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    padding: 13px 14px;
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    padding: 5px 14px;
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    padding: 3px 9px;
    font-size: 12px;
  }
`;

const Input = styled.input`
  ${InputsCss};
  height: 55px;
  ${({ theme }) => theme.adaptive.main.xl} {
    height: 47px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    height: 43px;
  }
  ${({ theme }) => theme.adaptive.modal.md} {
    height: 34px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    height: 34px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    height: 55px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    height: 35px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    height: 23px;
  }
`;

const TextArea = styled.textarea`
  ${InputsCss};
  height: 118px;
  resize: none;
  ${({ theme }) => theme.adaptive.main.xl} {
    height: 100px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    height: 92px;
  }
  ${({ theme }) => theme.adaptive.modal.md} {
    height: 73px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    height: 73px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    height: 85px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    height: 65px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    height: 44px;
  }
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  ${({ theme }) => theme.adaptive.main.xl} {
    margin-top: 40px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    margin-top: 39px;
  }
  ${({ theme }) => theme.adaptive.modal.md} {
    margin-top: 31px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    margin-top: 31px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    margin-top: 40px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    margin-top: 27px;
  }
`;

const SendBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  svg {
    width: 100%;
    height: 100%;
    fill: #3a3835;
    transition: 0.4s;
  }
`;

const SendWrapper = styled.button`
  position: relative;
  width: 535px;
  height: 70px;
  padding: 25px 36px;
  font-weight: 500;
  font-size: 28px;
  line-height: 22px;
  text-align: left;
  text-transform: uppercase;
  color: #fff0cf;
  outline: none;
  border: 0;
  z-index: 1;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 458px;
    height: 59px;
    padding: 21px 30px;
    font-size: 24px;
    line-height: 18px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 416px;
    height: 54px;
    padding: 19px 28px;
    font-size: 22px;
    line-height: 17px;
  }
  ${({ theme }) => theme.adaptive.modal.md} {
    width: 332px;
    height: 44px;
    padding: 16px 22px;
    font-size: 18px;
    line-height: 13px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 332px;
    height: 44px;
    padding: 16px 22px;
    font-size: 18px;
    line-height: 13px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 241px;
    height: 70px;
    padding: 26px 15px;
    font-size: 18px;
    line-height: 20px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    width: 221px;
    height: 64px;
    padding: 24px 14px;
    font-size: 17px;
    line-height: 18px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 198px;
    height: 43px;
    padding: 16px 10px;
    font-size: 14px;
    line-height: 12px;
  }
  &:hover ${SendBg} svg {
    fill: #8cc8bc;
  }
`;

const Close = styled.button`
  width: 70px;
  height: 70px;
  background: url("${closeImg}") center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 59px;
    height: 59px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 54px;
    height: 54px;
  }
  ${({ theme }) => theme.adaptive.modal.md} {
    width: 44px;
    height: 44px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 44px;
    height: 44px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 70px;
    height: 70px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    width: 64px;
    height: 64px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 43px;
    height: 43px;
  }
`;

const Success = styled.div<SuccessProps>`
  position: absolute;
  display: ${({ active }) => (active ? "block" : "none")};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 63px 81px 54px 66px;
  background: #fff0cf;
  z-index: 2;
  ${({ theme }) => theme.adaptive.main.xl} {
    padding: 53px 69px 56px 56px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    padding: 49px 63px 42px 51px;
  }
  ${({ theme }) => theme.adaptive.modal.md} {
    padding: 39px 50px 34px 41px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    padding: 39px 50px 34px 41px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    padding: 42px 40px 43px 43px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    padding: 28px 39px 55px 42px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    padding: 22px 30px 0;
  }
`;

const SuccessTitle = styled.div`
  width: 100%;
  height: 118px;
  margin-bottom: 45px;
  background: url("${successTitleImg}") center no-repeat;
  ${({ theme }) => theme.adaptive.main.xl} {
    height: 100px;
    margin-bottom: 43px;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    height: 90px;
    margin-bottom: 39px;
  }
  ${({ theme }) => theme.adaptive.modal.md} {
    height: 73px;
    margin-bottom: 31px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    height: 73px;
    margin-bottom: 31px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    height: 53px;
    margin-bottom: 29px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    width: 289px;
    height: 49px;
    margin-bottom: 27px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 259px;
    height: 44px;
    margin-bottom: 17px;
  }
`;

const SuccessText = styled.div`
  font-family: "ABCDiatypeMono";
  max-width: 475px;
  font-weight: 300;
  font-size: 18px;
  line-height: 130%;
  color: #252323;
  margin-bottom: 44px;
  ${({ theme }) => theme.adaptive.main.xl} {
    max-width: 441px;
    font-size: 16px;
    margin-bottom: 37px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    max-width: 375px;
    font-size: 14px;
    margin-bottom: 34px;
  }
  ${({ theme }) => theme.adaptive.modal.md} {
    max-width: 325px;
    font-size: 12px;
    margin-bottom: 27px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    max-width: 325px;
    font-size: 12px;
    margin-bottom: 27px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    max-width: 314px;
    font-size: 18px;
    margin-bottom: 35px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    max-width: 290px;
    font-size: 16px;
    margin-bottom: 32px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    max-width: 259px;
    font-size: 12px;
    margin-bottom: 10px;
  }
`;

const SuccessImg = styled.div`
  width: 100%;
  height: 232px;
  background: url("${successImg}") center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.main.xl} {
    height: 197px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    height: 180px;
  }
  ${({ theme }) => theme.adaptive.modal.md} {
    height: 144px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    height: 144px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    height: 210px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    height: 184px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    height: 109px;
  }
`;

const SuccessBtn = styled.div`
  width: 608px;
  height: 70px;
  background: url("${successBtn}") center no-repeat;
  background-size: cover;
  opacity: 0.1;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 517px;
    height: 59px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 472px;
    height: 54px;
  }
  ${({ theme }) => theme.adaptive.modal.md} {
    width: 378px;
    height: 44px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 378px;
    height: 44px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 242px;
    height: 71px;
    background: url("${successBtnSm}") center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    width: 224px;
    height: 65px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 192px;
    height: 42px;
    background: url("${successBtnXs}") center no-repeat;
    background-size: cover;
  }
`;
