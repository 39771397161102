import daoSlide from 'img/new/about/slides/dao.svg'
import idoSlide from 'img/new/about/slides/ido.svg'
import indexPoolSlide from 'img/new/about/slides/index-pool.svg'
import tiersSlide from 'img/new/about/slides/smarter-tiers.svg'
import dualTokenSlide from 'img/new/about/slides/dual-token-economics.svg'

export interface SlideProps {
  img: string
  title: string
  titleSpan: string
  text: string
}

const SlideList: SlideProps[] = [
  {
    title: 'Dual Token ',
    titleSpan: 'Economics',
    text: 'PRIME is our main utility token, staked for governance and IDO alos. SPIX is our index reward token. Every PRIME staker can yield exposure to EVERY IDO through SPIX.',
    img: dualTokenSlide,
  },
  {
    title: 'SMARTER ',
    titleSpan: 'TIERS',
    text: 'The higher your stake in PRIME, the higher your allocation odds. However, the smaller your PRIME stake (and the earlier you stake), the higher per-staked-token SPIX reward. Double quadratic distribution at its best!',
    img: tiersSlide,
  },
  {
    title: 'Index ',
    titleSpan: 'Pool',
    text: 'Every project that launches and IDOs on the SolanaPrime pays a platform fee to the SPIX pool. This pool will mint the $SPIX token across all the PRIME stakers.',
    img: indexPoolSlide,
  },
  {
    title: 'IDO ',
    titleSpan: 'Insurance',
    text: 'PRIME stakers can buy insurance against short-term IDO token performance. Insurance fees fund the PRIME Insurance Fund.',
    img: idoSlide,
  },
  {
    title: 'DAO ',
    titleSpan: '',
    text: 'PRIME is used to vote on system parameters, introduce improvement proposals, and other initiatives.',
    img: daoSlide,
  },
]

export default SlideList
