import AIBCLogo from 'img/new/partners/logo/aibc.png'
import NextChymiaLogo from 'img/new/partners/logo/next_chymia_logo.png'
import PenguinFinanceLogo from 'img/new/partners/logo/penguin-finance.svg'
import DafiLogo from 'img/new/partners/logo/dafi.png'
import PenguinLogo from 'img/new/partners/logo/penguin.png'
import ZBSLogo from 'img/new/partners/logo/zbs.png'
import PlutoLogo from 'img/new/partners/logo/pluto.png'
import StratosphereLogo from 'img/new/partners/logo/stratosphere.png'
import InfluxLogo from 'img/new/partners/logo/influx.png'
import BaseLayerLogo from 'img/new/partners/logo/baselayer.png'
import ContangoLogo from 'img/new/partners/logo/contango.png'
import SheeshaLogo from 'img/new/partners/logo/sheesha.png'
import NodeseedsLogo from 'img/new/partners/logo/nodeseeds.png'
import BigBrainLogo from 'img/new/partners/logo/big-brain.png'
import FulboLogo from 'img/new/partners/logo/fulbo.png'
import KolNetLogo from 'img/new/partners/logo/kolnet.svg'
import RDKLogo from 'img/new/partners/logo/rdk.png'
import AustraliaLogo from 'img/new/partners/logo/australia.png'
import ArenumLogo from 'img/new/partners/logo/arenum.svg'
import FacultyLogo from 'img/new/partners/logo/faculty.svg'
import Sl2Logo from 'img/new/partners/logo/sl2.svg'

const partnersList = [
  {
    logo: AIBCLogo,
    link: '',
  },
  {
    logo: NextChymiaLogo,
    link: 'https://chymia.io/',
  },
  // {
  //   logo: PenguinFinanceLogo,
  //   link: '',
  // },
  {
    logo: DafiLogo,
    link: 'https://www.dafiprotocol.io/',
  },
  {
    logo: PenguinLogo,
    link: 'https://png.fi/',
  },
  {
    logo: ZBSLogo,
    link: 'https://zbs.capital/',
  },
  {
    logo: PlutoLogo,
    link: 'https://plutodigital.com/',
  },
  {
    logo: StratosphereLogo,
    link: 'https://stratosph.com/',
  },
  {
    logo: InfluxLogo,
    link: 'https://www.influxgroup.io/',
  },
  // {
  //   logo: BaseLayerLogo,
  //   link: '',
  // },
  {
    logo: ContangoLogo,
    link: 'https://www.contango.digital/',
  },
  {
    logo: SheeshaLogo,
    link: 'https://www.sheeshafinance.io/',
  },
  {
    logo: NodeseedsLogo,
    link: 'https://nodeseeds.com/',
  },
  {
    logo: BigBrainLogo,
    link: 'https://www.bigbrain.holdings/',
  },
  // {
  //   logo: FulboLogo,
  //   link: '',
  // },
  {
    logo: KolNetLogo,
    link: 'https://kolnet.io/',
  },
  {
    logo: RDKLogo,
    link: 'https://rdk.ae/',
  },
  {
    logo: AustraliaLogo,
    link: 'https://blockchainaustralia.com.au/',
  },
  // {
  //   logo: ArenumLogo,
  //   link: '',
  // },
  {
    logo: FacultyLogo,
    link: 'https://www.facultycapital.com/',
  },
  {
    logo: Sl2Logo,
    link: 'https://sl2.capital/',
  },
]

export default partnersList
