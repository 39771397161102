import gsap, { Linear } from 'gsap'

export const FadeOut = (elem: gsap.DOMTarget) => {
  gsap.to(elem, {
    scrollTrigger: {
      trigger: elem,
      start: 'top top',
      end: '100% 50%',
      scrub: true,
    },
    opacity: 0,
  })
}

export const FadeIn = (elem: gsap.DOMTarget) => {
  gsap.to(elem, {
    scrollTrigger: {
      trigger: elem,
      start: 'top top',
      end: '100% 50%',
      scrub: true,
    },
    opacity: 1,
  })
}
