import React from 'react'
import styled from 'styled-components'
import logo from 'img/new/footer/logo.svg'
import footerBg from 'img/new/footer/bg.png'
import footerBgSm from 'img/new/footer/bg-sm.png'
import robotImg from 'img/new/footer/robot.png'
import numberImg from 'img/new/footer/numbers.svg'
import Socials from './socials'
import { Container } from 'theme/theme'
import { APPLY_LINK, DECK, FAQ_LINK, MEDIUM_LINK } from 'configs/app'

interface modalProps {
  openModal: () => void
}

const Footer: React.FunctionComponent<modalProps> = (props) => {
  return (
    <Root>
      <Container>
        <Logo />
        <Links>
          <Link href={APPLY_LINK} target={'_blank'}>
            Apply
          </Link>
          <Link href={MEDIUM_LINK} target={'_blank'}>
            Blog
          </Link>
          <Link href={DECK} target={'_blank'}>
            Deck
          </Link>
          <Link onClick={props.openModal}>Contact Us</Link>
          <Link href={FAQ_LINK} target={'_blank'}>
            FAQ
          </Link>
        </Links>
        <SocialsWrap>
          <Socials isFooter={true} fill={'#3A3734'} />
        </SocialsWrap>
        <Numbers />
        <Robot />
        <VcDao>SolanaPrime DAO</VcDao>
      </Container>
      <JapanText>この番組は、ご覧のスポンサーの提供でお送りします</JapanText>
    </Root>
  )
}

export default Footer

const Root = styled.div`
  position: relative;
  padding-top: 70px;
  padding-bottom: 82px;
  margin-top: -90px;
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    padding-bottom: 62px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    padding-top: 37px;
    padding-bottom: 35px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    padding-top: 30px;
    padding-bottom: 24px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    padding-top: 92px;
    padding-bottom: 61px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    padding-top: 99px;
    padding-bottom: 94px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1923px;
    height: 379px;
    background: url('${footerBg}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.newDesign.slg} {
      width: 1651px;
      height: 345px;
    }
    ${({ theme }) => theme.adaptive.newDesign.xmd} {
      width: 1040px;
      height: 221px;
    }
    ${({ theme }) => theme.adaptive.newDesign.md} {
      width: 818px;
      height: 185px;
      background-size: 1251px;
    }
    ${({ theme }) => theme.adaptive.newDesign.sm} {
      width: 986px;
      height: 546px;
      background: url('${footerBgSm}') center no-repeat;
      background-size: cover;
    }
  }
  ${Container} {
    position: relative;
  }
`

const Logo = styled.div`
  position: relative;
  width: 335px;
  height: 36px;
  background: url('${logo}') center no-repeat;
  background-size: cover;
  margin-bottom: 72px;
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 268px;
    height: 29px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 249px;
    height: 27px;
    margin-bottom: 69px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    width: 188px;
    height: 20px;
    margin-bottom: 46px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 138px;
    height: 15px;
    margin-bottom: 27px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 177px;
    height: 19px;
    margin-bottom: 48px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    width: 224px;
    height: 24px;
    margin-bottom: 97px;
  }
`

const Links = styled.div`
  display: flex;
  margin-bottom: 78px;
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    margin-bottom: 48px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    margin-bottom: 17px;
    flex-wrap: wrap;
    max-width: 240px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    margin-bottom: 55px;
    max-width: 180px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    margin-bottom: 66px;
  }
`

const Link = styled.a`
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #3a3734;
  opacity: 0.7;
  margin-right: 66px;
  cursor: pointer;
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 14px;
    line-height: 18px;
    margin-right: 43px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 12px;
    line-height: 15px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    margin-right: 31px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 18px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 90px;
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 19px;
    margin-right: 0;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    margin-bottom: 28px;
  }
  &:last-child {
    margin: 0;
  }
`

const SocialsWrap = styled.div``

const Robot = styled.div`
  position: absolute;
  top: -212px;
  right: -217px;
  width: 496px;
  height: 953px;
  background: url('${robotImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 429px;
    height: 823px;
    right: -167px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 343px;
    height: 659px;
    right: -130px;
    top: -175px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    width: 246px;
    height: 473px;
    right: -156px;
    top: -133px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 191px;
    height: 364px;
    right: -70px;
    top: -97px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    display: none;
  }
`

const Numbers = styled.div`
  position: absolute;
  width: 181px;
  height: 53px;
  top: -18px;
  right: 270px;
  background: url('${numberImg}') center no-repeat;
  background-size: contain;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 143px;
    height: 42.03px;
    top: -18px;
    right: 257px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 122px;
    height: 36px;
    top: 25px;
    right: 217px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 95px;
    height: 28px;
    top: 0;
    right: 170px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    top: -20px;
    right: 70px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 52px;
    height: 15px;
    right: 97px;
    top: 0;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 99px;
    height: 29px;
    right: -20px;
    top: 43px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    width: 95px;
    height: 28px;
    right: -13px;
    top: 59px;
  }
`

const VcDao = styled.div`
  position: absolute;
  bottom: 0px;
  right: 270px;
  font-family: 'ABCDiatype';
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #3a3734;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    right: 257px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    right: 217px;
    font-size: 13px;
    line-height: 16px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    right: 170px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    right: 70px;
    font-size: 8px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    right: 97px;
    font-size: 7px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    bottom: unset;
    top: 250px;
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    font-size: 10px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    top: 366px;
    font-size: 11px;
  }
`

const JapanText = styled.div`
  position: absolute;
  top: -9px;
  left: 50%;
  transform: translateX(-50%);
  font-family: 'ABCDiatype';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #8cc8bc;
  opacity: 0.5;
  max-width: 377px;
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 21px;
    line-height: 26px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    font-size: 11px;
    line-height: 14px;
    max-width: 232px;
    top: 1px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 8px;
    line-height: 10px;
    max-width: 164px;
    top: -3px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    font-size: 12px;
    line-height: 15px;
    max-width: 216px;
    top: -4px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    top: -2px;
  }
`
