import React from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as Telegram } from 'img/new/footer/socials/telegram.svg'
import { ReactComponent as Twitter } from 'img/new/footer/socials/twitter.svg'
import { ReactComponent as Medium } from 'img/new/footer/socials/medium.svg'
import { ReactComponent as Youtube } from 'img/new/footer/socials/youtube.svg'
import { ReactComponent as Discord } from 'img/new/footer/socials/discord.svg'
import {
  DISCORD_LINK,
  MEDIUM_LINK,
  TELEGRAM_LINK,
  TWITTER_LINK,
} from '../../../../configs/app'

interface socialsProps {
  fill: string
  isFooter: boolean
}

const Socials: React.FunctionComponent<socialsProps> = (props) => {
  return (
    <Root isFooter={props.isFooter}>
      <Link fill={props.fill} href={TELEGRAM_LINK} target={'_blank'}>
        <Telegram />
      </Link>
      <Link fill={props.fill} href={TWITTER_LINK} target={'_blank'}>
        <Twitter />
      </Link>
      <Link fill={props.fill} href={MEDIUM_LINK} target={'_blank'}>
        <Medium />
      </Link>
      {/*<Link fill={props.fill} href={'/'} target={'_blank'}>*/}
      {/*  <Youtube />*/}
      {/*</Link>*/}
      <Link fill={props.fill} href={DISCORD_LINK} target={'_blank'}>
        <Discord />
      </Link>
    </Root>
  )
}

export default Socials

interface linkProps {
  fill: string
}

const Link = styled.a<linkProps>`
  display: flex;
  max-width: 23px;
  height: 16px;
  margin-right: 44px;
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    max-width: 20px;
    height: 14px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    max-width: 21px;
    height: 14px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    max-width: 14px;
    height: 10px;
    margin-right: 30px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    max-width: 13px;
    height: 9px;
    margin-right: 23px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    margin-right: 15px;
  }
  svg {
    display: block;
    max-width: 23px;
    height: 100%;
    fill: ${({ fill }) => (fill ? fill : '')};
    ${({ theme }) => theme.adaptive.newDesign.lg} {
      max-width: 20px;
    }
    ${({ theme }) => theme.adaptive.newDesign.xmd} {
      max-width: 14px;
    }
    ${({ theme }) => theme.adaptive.newDesign.md} {
      max-width: 13px;
    }
  }
  &:last-child {
    margin-right: 0;
  }
`

interface rootProps {
  isFooter: boolean
}

const Root = styled.div<rootProps>`
  position: relative;
  display: flex;
  ${Link} {
    ${({ isFooter }) => {
      if (isFooter)
        return css`
          ${({ theme }) => theme.adaptive.newDesign.sm} {
            max-width: 17px;
            height: 12px;
            margin-right: 24px;
            svg {
              max-width: 17px;
            }
          }
          ${({ theme }) => theme.adaptive.newDesign.xsm} {
            max-width: 26px;
            height: 17px;
            margin-right: 25px;
            svg {
              max-width: 26px;
            }
          }
        `
    }}
  }
`
