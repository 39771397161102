import React, { useRef } from 'react'
import styled, { css } from 'styled-components'
import logoImg from 'img/new/main/logo.svg'
import arrowImg from 'img/new/main/arrow.svg'
import Socials from '../footer/socials'
import { Container } from 'theme/theme'
import { APP_LINK, BUY_TOKEN_LINK } from 'configs/app'

const Main: React.FunctionComponent = () => {
  return (
    <Root>
      <Container>
        <TitleWrap>
          <Logo />
          <SubTitle>Your gateway to the Solana ecosystem</SubTitle>
          <BtnRow>
            <BtnWrap>
              <LaunchBtn href={APP_LINK} target="_blank">
                Launch app
              </LaunchBtn>
              <JapanText>アプリを起動</JapanText>
            </BtnWrap>
            <BtnWrap>
              <BuyBtn href={BUY_TOKEN_LINK} target="_blank">
                Buy PRIME <span>token</span>
              </BuyBtn>
              <JapanText>トークンを購入する</JapanText>
            </BtnWrap>
          </BtnRow>
        </TitleWrap>
        <Row>
          <Japan>ソラナエコシステムのゲートウェイ</Japan>
          <SocialsWrap>
            <Socials isFooter={false} fill={'#ED1651'} />
          </SocialsWrap>
        </Row>
        <Arrow />
      </Container>
    </Root>
  )
}

export default Main

const Root = styled.div`
  position: relative;
  padding-top: 125px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    padding-top: 121px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    padding-top: 91px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    padding-top: 70px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    padding-top: 69px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    padding-top: 26px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    padding-top: 27px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    padding-top: 17px;
  }
  ${Container} {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
`

const TitleWrap = styled.div`
  position: relative;
  width: 780px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 683px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 586px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 552px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    width: 357px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 255px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 100%;
  }
`

const Logo = styled.div`
  position: relative;
  width: 100%;
  height: 274px;
  background: url('${logoImg}') center no-repeat;
  background-size: cover;
  margin-bottom: 31px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    height: 243px;
    margin-bottom: 35px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    height: 210px;
    margin-bottom: 29px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    height: 197px;
    margin-bottom: 28px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    height: 128px;
    margin-bottom: 25px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    height: 90px;
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    height: 98px;
    margin-bottom: 14px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    height: 87px;
    margin-bottom: 20px;
  }
`

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  letter-spacing: -0.05em;
  color: #fff0cf;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 23px;
    line-height: 29px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 20px;
    line-height: 25px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 19px;
    line-height: 23px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    font-size: 11px;
    line-height: 13px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 11px;
    line-height: 13px;
    width: 188px;
    margin: 0 auto;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    font-size: 12px;
    line-height: 14px;
    width: 100%;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    font-size: 11px;
    width: 162px;
  }
`

const JapanText = styled.div`
  font-family: 'NotoSans';
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  color: #fff0cf;
  opacity: 0.5;
  margin-top: 16px;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 10px;
    line-height: 14px;
    margin-top: 13px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 9px;
    line-height: 13px;
    margin-top: 11px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    margin-top: 10px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    font-size: 7px;
    line-height: 10px;
    margin-top: 8px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 5px;
    line-height: 7px;
    margin-top: 5px;
  }
`

const BtnRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    margin-top: 50px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    margin-top: 40px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    margin-top: 30px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    margin-top: 20px;
  }
`

const BtnWrap = styled.div`
  &:last-child {
    margin-left: 26px;
    ${({ theme }) => theme.adaptive.newDesign.xl} {
      margin-left: 20px;
    }
    ${({ theme }) => theme.adaptive.newDesign.lg} {
      margin-left: 17px;
    }
    ${({ theme }) => theme.adaptive.newDesign.slg} {
      margin-left: 16px;
    }
    ${({ theme }) => theme.adaptive.newDesign.xmd} {
      margin-left: 13px;
    }
    ${({ theme }) => theme.adaptive.newDesign.md} {
      margin-left: 8px;
    }
    ${({ theme }) => theme.adaptive.newDesign.sm} {
      margin-left: 9px;
    }
    ${({ theme }) => theme.adaptive.newDesign.xsm} {
      margin-left: 10px;
    }
  }
`

const BtnCss = css`
  font-family: 'PressStart2P';
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 274px;
  height: 81px;
  font-size: 16px;
  text-transform: uppercase;
  color: #fff0cf;
  z-index: 5;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 218px;
    height: 64px;
    font-size: 13px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 186px;
    height: 55px;
    font-size: 11px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 174px;
    height: 51px;
    font-size: 10px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    width: 140px;
    height: 41px;
    font-size: 8px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 102px;
    height: 20px;
    font-size: 6px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 104px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    width: 107px;
  }
`

const LaunchBtn = styled.a`
  ${BtnCss};
  background: #ed1651;
  &:before {
    content: '';
    position: absolute;
    width: 5px;
    height: 100%;
    right: 0;
    top: 0;
    background: #fff0cf;
    ${({ theme }) => theme.adaptive.newDesign.xl} {
      width: 4px;
    }
    ${({ theme }) => theme.adaptive.newDesign.slg} {
      width: 3px;
    }
    ${({ theme }) => theme.adaptive.newDesign.md} {
      width: 2px;
    }
  }
  &:hover {
    background: #ff3d72;
  }
`

const BuyBtn = styled.a`
  ${BtnCss};
  flex-direction: column;
  background: #8cc8bc;
  border-radius: 4px;
  line-height: 21px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    border-radius: 3px;
    line-height: 18px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    line-height: 16px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    line-height: 15px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    border-radius: 2px;
    line-height: 13px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    line-height: 100%;
    flex-direction: row;
  }
  span {
    display: block;
    width: 100%;
    text-align: center;
    ${({ theme }) => theme.adaptive.newDesign.md} {
      width: unset;
    }
  }
`

const Row = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: end;
  margin-top: 48px;
  z-index: 2;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    margin-top: 67px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    margin-top: 64px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    margin-top: 37px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    margin-top: 33px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    margin-top: 2px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    margin-top: 18px;
    justify-content: center;
    flex-wrap: wrap;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    margin-top: 20px;
  }
`

const Japan = styled.div`
  font-family: 'ABCDiatype';
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #ed1651;
  max-width: 209px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 18px;
    line-height: 22px;
    max-width: 209px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 16px;
    line-height: 20px;
    max-width: 151px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 11px;
    line-height: 13px;
    max-width: 116px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    font-size: 7px;
    line-height: 8px;
    max-width: 76px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 4px;
    line-height: 5px;
    max-width: 44px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    order: 2;
    margin: 14px auto 0;
    text-align: center;
    min-width: 75px;
    font-size: 7px;
    line-height: 9px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    margin: 13px auto 0;
    min-width: 90px;
    font-size: 8px;
    line-height: 10px;
  }
`

const Arrow = styled.div`
  width: 63px;
  height: 65px;
  background: url('${arrowImg}') center no-repeat;
  background-size: cover;
  opacity: 0.08;
  margin: 6px auto 0;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 33px;
    height: 34px;
    margin: 0px auto;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 55px;
    height: 57px;
    margin: 10px auto 0;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 63px;
    height: 65px;
    margin: 0px auto;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    width: 42px;
    height: 43px;
    margin: 15px auto 0;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 38px;
    height: 39px;
    margin: 28px auto 0;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 20px;
    height: 21px;
    margin: 20px auto 0;
  }
  ${({ theme }) => theme.adaptive.newDesign.xsm} {
    margin: 22px auto 0;
  }
`

const SocialsWrap = styled.div`
  margin-left: 120px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    margin-left: 100px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    margin-left: 93px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    margin-left: 30px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xmd} {
    margin-left: 30px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    margin-left: 25px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    order: 1;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`
