import React from "react";

const SendSm: React.FunctionComponent = () => (
  <svg
    width="241"
    height="71"
    viewBox="0 0 241 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0.0394592H161L215 70.0395H0V0.0394592Z" />
    <path d="M173 -3.05176e-05H186.85L241 70H227.15L173 -3.05176e-05Z" />
  </svg>
);

export default SendSm;
