import React from "react";

const Send: React.FunctionComponent = () => (
  <svg
    width="535"
    height="70"
    viewBox="0 0 535 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0.000488281H323L377 70.0005H0V0.000488281Z" />
    <mask
      id="mask0_76"
      maskUnits="userSpaceOnUse"
      x="272"
      y="0"
      width="263"
      height="71"
    >
      <path
        d="M272 0.000488281H485L535 70.0005H272V0.000488281Z"
        fill="#C4C4C4"
      />
    </mask>
    <g mask="url(#mask0_76)">
      <path d="M164.916 -0.494019H201.874L297.616 123.185H260.658L164.916 -0.494019Z" />
      <path d="M234.76 -0.494263H271.718L367.46 123.185H330.502L234.76 -0.494263Z" />
      <path d="M304.601 -0.494263H341.559L437.301 123.185H400.343L304.601 -0.494263Z" />
      <path d="M374.443 -0.494263H411.401L507.144 123.185H470.185L374.443 -0.494263Z" />
      <path d="M443.122 -0.494263H480.08L575.822 123.185H538.864L443.122 -0.494263Z" />
      <path d="M512.731 -0.494263H549.689L645.432 123.185H608.473L512.731 -0.494263Z" />
    </g>
  </svg>
);

export default Send;
