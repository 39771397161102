import gsap, { Linear } from 'gsap'

export const LeftCurtainAnimation = (elem: gsap.DOMTarget) => {
  gsap.to(elem, {
    scrollTrigger: {
      trigger: elem,
      start: 'top center',
      end: '30% 50%',
    },
    duration: 0.7,
    right: '75%',
    zIndex: 1,
  })
}

export const RightCurtainAnimation = (elem: gsap.DOMTarget) => {
  gsap.to(elem, {
    scrollTrigger: {
      trigger: elem,
      start: 'top center',
      end: '30% 50%',
    },
    duration: 0.7,
    left: '75%',
    zIndex: 1,
  })
}
