import React, { useRef } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { noop } from "lodash";
import useOnClickOutside from "helpers/hooks/useOnClickOutside";
import ContactForm from "../forms/ContactForm";

export interface ModalProps {
  active?: boolean;
  onClose?(): void;
  children?: React.ReactNode;
}

const portal = document.getElementById("root");

function ModalTemplate(props: ModalProps) {
  const { active = false, onClose = noop, children = null } = props;

  const modalWindow = useRef(null);

  useOnClickOutside({
    ref: modalWindow,
    handler: () => onClose(),
  });

  if (!portal || !active) {
    return null;
  }

  return createPortal(
    <ModalTemplateRoot>
      <Modal ref={modalWindow}>
        <ContactForm onClose={onClose} />
      </Modal>
    </ModalTemplateRoot>,
    portal
  );
}

export default ModalTemplate;

const ModalTemplateRoot = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 10;
`;

const Modal = styled.div`
  position: relative;
`;
